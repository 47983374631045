import React, {Fragment, useContext, useEffect, useState} from 'react';
import './Schedule.scss';
import {combineNames} from "../../logic/objects";
import {SideScroll} from "../portable/side_scroll/SideScroll";
import RequestHandlerContext from "../../contexts/RequestHandler";
import {ShowOccurrence} from "../../logic/objects/timeslots";
import moment from "moment/moment";

const itemWidthLarge = 500;
const itemWidthSmall = 350;

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

/**
 * Returns the first {@link ShowOccurrence} in {@link occurrences} that is actively happening.
 *
 * @param occurrences The occurrences to check
 * @return The first occurrence that is currently happening, or `undefined`
 */
function getCurrent(occurrences: ShowOccurrence[]): ShowOccurrence | undefined {
    for (let occurrence of occurrences) {
        if (isNow(occurrence)) {
            return occurrence
        }
    }

    return undefined
}

/**
 * Gets the {@link ShowOccurrence}s from {@link occurrences} that are today but haven't happened yet.
 *
 * @param occurrences
 */
function getNextItems(occurrences: ShowOccurrence[]): ShowOccurrence[] {
    let items: ShowOccurrence[] = []

    for (let i = 0; i < occurrences.length - 1; i++) {
        if (isToday(occurrences[i]) && isFuture(occurrences[i])) {
            items.push(occurrences[i])
        }
    }

    return items
}

/**
 * Checks if the {@link ShowOccurrence} is currently happening.
 *
 * @param occurrence The occurrence to check
 * @return If the current time falls between the occurrence start and end times
 */
function isNow(occurrence: ShowOccurrence): boolean {
    let today = new Date()
    return occurrence.start <= today.getTime() && today.getTime() <= occurrence.end
}

/**
 * Checks if the {@link ShowOccurrence} will happen in the future.
 *
 * @param occurrence The occurrence to check
 * @return If the occurrence start date is in the future
 */
function isFuture(occurrence: ShowOccurrence): boolean {
    let today = new Date()
    return occurrence.start > today.getTime()
}

/**
 * Checks if the {@link ShowOccurrence} is happening/has happened today.
 *
 * @param occurrence The occurrence to check
 * @return If the occurrence happens today
 */
function isToday(occurrence: ShowOccurrence): boolean {
    let today = new Date()
    let occurStart = new Date(occurrence.start)
    return occurStart.getDate() == today.getDate()
}

/**
 * A schedule of DJ shows for the current week.
 */
export const Schedule = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The show occurrences that are being displayed this week.
     */
    const [occurrences, setOccurrences] = useState<ShowOccurrence[]>([]);

    /**
     * The occurrence currently happening, or `undefined` if none are.
     */
    const [current, setCurrent] = useState<ShowOccurrence | undefined>()

    /**
     * The occurrences that are happening today but in the future.
     */
    const [nextItems, setNextItems] = useState<ShowOccurrence[]>([])

    /**
     * The width of each occurrence display. This value is needed for the {@link SideScroll} component.
     */
    const [itemWidth, setItemWidth] = useState(itemWidthLarge);

    const today = new Date()

    /**
     * Lists the occurrences for the week.
     */
    const listOccurrences = (): void => {
        requestHandler.request('/api/show/occurrence/list/week')
            .then(async res => {
                let json = await res.json()
                let showOccurrences = json as ShowOccurrence[]

                setOccurrences(showOccurrences)
                setNextItems(getNextItems(showOccurrences))
                setCurrent(getCurrent(showOccurrences))
            })
    }

    /**
     * Initially lists the show occurrences, and also adds a resize hook to the window.
     */
    useEffect(() => {
        listOccurrences()

        /**
         * Controls the {@link itemWidth} depending on the size of the window. Invoked on the window event `resize`.
         *
         * @param e The resize event
         */
        const onResize = (e: UIEvent) => {
            setItemWidth(window.matchMedia("(max-width: 576px)").matches ? itemWidthSmall : itemWidthLarge)
        }

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    /**
     * Strips the minutes off of a `h:mm` formatted string if the minutes are `00`. For example, `8:00` would return
     * `8`.
     *
     * @param time The time string to format
     * @return The stripped time string
     */
    const stripMinutes = (time: string): string => {
        if (time.endsWith(':00')) {
            return time.substring(0, time.length - 3)
        }

        return time
    }

    /**
     * Formats a string of the time the {@link ShowOccurrence} is happening.
     *
     * @param occurrence The occurrence to display
     * @return The formatted string
     */
    const timeString = (occurrence: ShowOccurrence): string => {
        let start = new Date(occurrence.start)
        let end = new Date(occurrence.end)

        let startFormatted = moment(start).format('h:mm A').split(' ')
        let endFormatted = moment(end).format('h:mm A').split(' ')

        let startingAmPm = startFormatted[1] != endFormatted[1]

        let formatted = stripMinutes(startFormatted[0])

        if (startingAmPm) {
            formatted += ' ' + startFormatted[1]
        }

        formatted += ` - ${stripMinutes(endFormatted[0])} ${endFormatted[1]}`

        return formatted
    }

    /**
     * Creates a display of a {@link ShowOccurrence}.
     *
     * @param occurrence The occurrence to display
     * @return A display of an occurrence
     */
    const scheduleItem = (occurrence: ShowOccurrence): JSX.Element => {
        return (
            <div className="item col-12">
                <img className="image" alt="alt" src={occurrence.show.image}/>
                <div className="text-container">
                    <span className="title">{occurrence.show.name} with {combineNames(occurrence.show.owners)}</span>
                    <span className="time">{timeString(occurrence)}</span>
                    <p className="description">{occurrence.show.name} hosted by {combineNames(occurrence.show.owners)}</p>
                </div>
            </div>
        )
    }

    /**`
     * Gets a list of day names from today (inclusive) to 7 days from now (exclusive).
     *
     * @return A list of day names found in {@link days}
     */
    const daysList = (): string[] => {
        let day = today.getDay()
        let daysRes = []
        for (let i = 0; i < 7; i++) {
            daysRes.push(days[(day + i) % 7])
        }

        return daysRes
    }

    let first = true

    /**
     * Checks if the {@link ShowOccurrence} occurs on the day offset from now.
     *
     * @param occurrence The occurrence to check
     * @param dayFromNow The number of days from now the occurrence should be on
     * @return If the occurrence is a certain number of days from now
     */
    const isOnDay = (occurrence: ShowOccurrence, dayFromNow: number): boolean => {
        let givenDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + dayFromNow)
        let dayAfter = new Date(givenDay.getTime())
        dayAfter.setDate(givenDay.getDate() + 1)

        if (first) {
            first = false
        }

        return occurrence.start >= givenDay.getTime() && occurrence.start <= dayAfter.getTime()
    }

    return (
        <div className="Schedule">
            <h3 className="primary-title">Today
                - {days[today.getDay()]}, {months[today.getMonth()]} {today.getDate()}</h3>
            <div className="today d-flex flex-column flex-lg-row">
                {current != undefined &&
                    <Fragment>
                        <div className="on-air col-12 col-lg-auto">
                            <h3 className="on-air-title">On Air</h3>
                            {scheduleItem(current)}
                        </div>
                    </Fragment>}
                {nextItems.length > 0 &&
                    <div className="coming-next mt-3 mt-lg-0">
                        <h3 className="secondary-title">Up next...</h3>
                        <SideScroll items={nextItems.map(scheduleItem)} itemWidth={itemWidth}/>
                    </div>}
            </div>

            <h3 className="primary-title">This Week</h3>
            {daysList().map((day, index) =>
                <div className="this-week">
                    <div className="titled-list">
                        <h3 className="secondary-title">{day}</h3>
                        <div className="items">
                            {<SideScroll items={occurrences.filter(occurrence => isOnDay(occurrence, index)).map(scheduleItem)} itemWidth={itemWidth}/>}
                        </div>
                    </div>
                </div>)}
        </div>
    );
}
