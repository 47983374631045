import React, {useContext} from 'react'
import './AccountPanel.scss'
import RequestHandlerContext from "../../contexts/RequestHandler";
import {Link} from "react-router-dom";

/**
 * A panel for handling the currently logged-in user's account.
 */
export const AccountPanel = () => {
    const requestHandler = useContext(RequestHandlerContext)

    return (
        <div className="AccountPanel">
            <h2>Hello, {requestHandler.self?.firstName}.</h2>

            <Link to="/account/setup_2fa">Setup 2FA</Link>
        </div>
    )
}
