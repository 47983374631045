import React, {createRef, useContext, useEffect, useState} from 'react';
import './Songs.scss';
import {ArtworkDisplay} from "../portable/artwork_display/ArtworkDisplay";
import {DropdownDate} from "../portable/dropdown_date/DropdownDate";
import SearchDateContext from "../../contexts/SearchDate";
import {Service, Track, TrackHandler} from "@witr-radio/music-logger-service";
import {LOGGER_REQUEST_URL, TRACKS_PER_PAGE} from "../App";
import TrackMultiReceiverContext from "../../contexts/TrackMultiReceiver";
import {Button} from "../button/Button";
import {formatPlaylistDate, formatPlaylistTime} from "../../logic/date_utils";

/**
 * A page to display a list of recently played FM-playlist songs. This is essentially a limited logger.witr.rit.edu
 * implementation. There is little duplicated code between the two services due to the music-logger-service library.
 */
export const Songs = () => {
    const trackMultiReceiver = useContext(TrackMultiReceiverContext)

    /**
     * The search start date.
     */
    const [startDate, setStartDate] = useState<Date | undefined>()

    /**
     * The search end date.
     */
    const [endDate, setEndDate] = useState<Date | undefined>()

    /**
     * The tracks being displayed.
     */
    const [tracks, setTracks] = useState<Track[]>([])

    /**
     * The ID of the {@link trackMultiReceiver} so it can be unsubscribed to on unmount.
     */
    const [listenerIds, setListenerIds] = useState<number[]>([])

    /**
     * The handler to query tracks.
     */
    const [fmTrackHandler] = useState<TrackHandler>(new TrackHandler(setTracks, LOGGER_REQUEST_URL, false, TRACKS_PER_PAGE))
    const [udgTrackHandler] = useState<TrackHandler>(new TrackHandler(setTracks, LOGGER_REQUEST_URL, true, TRACKS_PER_PAGE))

    const [currentTrackHandler, setCurrentTrackHandler] = useState<TrackHandler>(trackMultiReceiver.underground ? udgTrackHandler : fmTrackHandler)

    /**
     * A reference to the artist search input element.
     */
    const [artist, setArtist] = useState<string>('')

    /**
     * A reference to the title search input element.
     */
    const [title, setTitle] = useState<string>('')

    /**
     * Loads tracks and subscribes to adding new ones to the list as they play.
     */
    useEffect(() => {
        // on mount
        currentTrackHandler.loadMoreTracks()
            .finally(() => {
                let trackListenerId = trackMultiReceiver.addListener(trackBroadcast => {
                    if (!currentTrackHandler.searching && !trackBroadcast.requested) {
                        currentTrackHandler.manualAddTrack(trackBroadcast.track)
                    }
                })

                let undergroundListenerId = trackMultiReceiver.addUndergroundListener(underground => {
                    let newHandler = underground ? udgTrackHandler : fmTrackHandler
                    setCurrentTrackHandler(newHandler)

                    clearSearch()
                    setTracks([])
                    newHandler.reset()
                    newHandler.loadMoreTracks()
                })

                setListenerIds([trackListenerId, undergroundListenerId])
            })

        return () => {
            // on unmount
            trackMultiReceiver.removeListener(...listenerIds)
        }
    }, [])

    /**
     * Loads more tracks through the {@link TrackHandler}.
     */
    const onLoadMore = (): void => {
        currentTrackHandler.loadMoreTracks()
    }

    /**
     * Submits a search using the artist, title and bounding dates.
     */
    const onSearch = (): void => {
        currentTrackHandler.searchTracks(artist, title, startDate, endDate)
    }

    /**
     * Clears all search fields.
     */
    const clearSearch = (): void => {
        setArtist('')
        setTitle('')
        setStartDate(undefined)
        setEndDate(undefined)
    }

    /**
     * Gets the first streaming link for the track, if any is found.
     *
     * @param track The track to get the streaming link for
     * @return The first streaming link
     */
    const getFirstStreamingLink = (track: Track): string | undefined => {
        let links = track.streaming.map(streamingService => streamingService.link)
        if (links.length == 0) {
            return undefined
        }

        return links[0]
    }

    return (
        <div className="Songs d-flex flex-row justify-content-center">
            <div className="content-wrapper d-flex row flex-column col-12 col-lg-10 col-xxl-8">
                <h3 className="playlist-title">FM Playlist</h3>
                <div className="search mt-4 mt-lg-5 d-flex flex-column flex-xl-row">
                    <div className="search-dates d-flex row mx-0 mt-xl-0 col-12 col-xl-6">
                        <SearchDateContext.Provider value={{date: startDate, setDate: setStartDate}}>
                            <DropdownDate className="col-6 between-above mx-0"/>
                        </SearchDateContext.Provider>

                        <SearchDateContext.Provider value={{date: endDate, setDate: setEndDate}}>
                            <DropdownDate className="col-6 right-dropdown mx-0"/>
                        </SearchDateContext.Provider>
                    </div>

                    <div className="search-fields d-flex row mx-0 mt-3 mt-xl-0 col-12 col-xl-6">
                        <div className="col-6">
                            <input type="text" placeholder="Artist" className="form-control underline-input col-12 gutter-margin mx-0" value={artist} onChange={e => setArtist(e.target.value)}/>
                        </div>
                        <div className="col-6">
                            <input type="text" placeholder="Title" className="form-control underline-input col-12 gutter-margin mx-0" value={title} onChange={e => setTitle(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="search-button d-flex justify-content-center mt-4 mb-4 mb-md-5">
                    <Button onClick={() => onSearch()}>Search</Button>
                </div>
                <div className="log">
                    <table>
                        <thead>
                        <tr>
                            <th>Song</th>
                            <th className="d-none d-md-table-cell">Date</th>
                            <th className="time d-none d-md-table-cell pe-3 text-end">Time</th>
                            <th className="date-time d-md-none text-end">Date/Time</th>
                            <th className="d-none d-sm-table-cell text-end">Streaming</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tracks.map(track => <tr key={`${track.id}-${trackMultiReceiver.underground}`}>
                            <td>
                                <ArtworkDisplay imageHref={getFirstStreamingLink(track)} image={track.getAlbumArt() ?? '/images/default_album.png'} alt="Artwork" primaryText={track.title} secondaryText={track.artist} imageWidth={50} imageHeight={50}/>
                            </td>
                            <td className="date d-none d-md-table-cell">{formatPlaylistDate(track.time)}</td>
                            <td className="time d-none d-md-table-cell">{formatPlaylistTime(track.time)}</td>
                            <td className="time-small d-md-none">
                                <span>{formatPlaylistDate(track.time)}</span>
                                <span>{formatPlaylistTime(track.time)}</span>
                            </td>
                            <td className="d-none d-sm-table-cell">{track.streaming.map(link => <a href={link.link} target="_blank" className="btn btn-outline-accent float-end">{Service[link.service!]}</a>)}</td>
                        </tr>)}
                        </tbody>
                    </table>
                    <div className="more">
                        <Button onClick={() => onLoadMore()}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
