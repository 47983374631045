import React, {createRef, useEffect, useState} from 'react'
import './Lister.scss'
import {Button} from "../../button/Button";
import {classes, State} from "../../../logic/react_utility";
import {Listable} from "../../../logic/objects";

/**
 * A signature to modify an item being displayed by its ID. If no matching item ID is found, it is added.
 */
export type ModifyItem<T extends Listable> = (item: T) => void

/**
 * A signature to delete an item being displayed by its ID.
 */
export type DeleteItem = (itemId: string) => void

/**
 * Properties for the top adding button, to add another of the object to be listed.
 */
export interface AddModalOptions<T extends Listable> {

    /**
     * The text on the button.
     */
    buttonText: string

    /**
     * The modal to display. This should always return the modal element, but should only be shown when the value of
     * `visibleState` is `true`.
     */
    modal: (visibleState: State<boolean>, modifyItem: ModifyItem<T>) => JSX.Element
}

/**
 * Options of fetching items that are fetched with one single link.
 */
export interface SingleListOptions<T extends Listable> {

    /**
     * Fetches all {@link items} to be displayed.
     */
    fetchItems: () => Promise<T[]>

    /**
     * A callback invoked when an item is dragged to be reordered via drag and drop. Items may be reordered if this is
     * defined. The job of this function is for the implementor to change the order of the item in its items list and
     * return the new, properly ordered list.
     *
     * @param item         The item being dragged and dropped
     * @param newIndex     The new index of the item
     * @param currentItems The current list of elements being displayed
     * @return The new, properly ordered item list
     */
    changeOrder?: (item: T, newIndex: number, currentItems: T[]) => T[]
}

/**
 * Options of fetching items that are paginated.
 */
export interface PaginationOptions<T extends Listable> {
    /**
     * Fetches the first page of {@link items} to be displayed.
     */
    initialItems: () => Promise<T[]>

    /**
     * Fetches the next page of {@link items}. If the page is unavailable, an empty list is returned.
     */
    nextPage: () => Promise<T[]>

    /**
     * Fetches the previous page of {@link items}. If the page is unavailable, an empty list is returned.
     */
    previousPage: () => Promise<T[]>

    /**
     * The current page being displayed, 1-indexed.
     */
    page: number

    /**
     * If the listing has a next available page.
     */
    hasNext: boolean

    /**
     * If the listing has a previous available page.
     */
    hasPrevious: boolean
}

/**
 * Displays a list of items with a fixed order.
 */
type DisplayItem<T extends Listable> = (item: T, modifyItem: ModifyItem<T>, deleteItem: DeleteItem) => JSX.Element

/**
 * Displays a list of items that may be reordered. Reordering should not be handled by the implementor.
 */
type DisplayReorderableItem<T extends Listable> = (item: T, modifyItem: ModifyItem<T>, deleteItem: DeleteItem, onHandleEvent: HandleEvent) => JSX.Element

export interface ListerProps<T extends Listable> {

    /**
     * Options for displaying items, either all at once or in pages.
     */
    displayOptions: SingleListOptions<T> | PaginationOptions<T>

    /**
     * Displays an item being listed. `modifyItem` is a function to modify the item in memory, with `deleteItem`
     * deleting it locally.
     */
    displayItem: DisplayItem<T> | DisplayReorderableItem<T>

    /**
     * The button to open a popup to add a new object being listed. If undefined, no button will be present.
     */
    addModalOptions?: AddModalOptions<T> | undefined

    /**
     * Any additional classes to add to the list that is every item's parent.
     */
    listClasses?: string | undefined

    /**
     * If the component should have a wrapper div with the class name of <Lister>. If the wrapper is enabled, there is
     * auto grid management of items. If unset, this defaults to `true`.
     */
    includeParent?: boolean | undefined
}

/**
 * Checks if the {@link ListerProps#displayOptions} is paginated.
 *
 * @param object The properties to check
 */
const isPaginated = (object: any): object is PaginationOptions<any> => {
    return 'hasNext' in object;
}

/**
 * The valid events for draggable items being displayed.
 */
export type HandleEventType = 'mousedown'

/**
 * Invoked when a mouse event occurs on the handle.
 */
export type HandleEvent = (type: HandleEventType, event: React.MouseEvent<any>) => void

type DragRef = { draggableRef: React.RefObject<HTMLDivElement>, placeholderRef: React.RefObject<HTMLDivElement> }

/**
 * A component to show and manage listable objects, in instances such as users, staff, shows, etc.
 */
export const Lister = <T extends Listable>({
                                               displayOptions,
                                               displayItem,
                                               addModalOptions,
                                               listClasses,
                                               includeParent
                                           }: ListerProps<T>) => {

    // This should never change across state and therefore is a normal constant.
    const paginated = isPaginated(displayOptions)

    const reorderable = !paginated && displayOptions.changeOrder != undefined
    const [itemRefs, setItemRefs] = useState<Map<T, DragRef>>(new Map())

    /**
     * The current items being displayed.
     */
    const [items, setItems] = useState<T[]>([])

    /**
     * The adding modal's show state, activated through the button if {@link addModalOptions} is defined.
     */
    const showModalState = useState<boolean>(false)
    const [showModal, setShowModal] = showModalState

    useEffect(() => {
        if (paginated) {
            displayOptions.initialItems().then(items => setItems(items))
        } else {
            displayOptions.fetchItems().then(items => {
                if (reorderable) {
                    setItemRefs(_ => {
                        setItems(items);
                        return new Map(items.map(item => [item, {
                            draggableRef: createRef<HTMLDivElement>(),
                            placeholderRef: createRef<HTMLDivElement>()
                        }]))
                    })
                }
            })
        }
    }, [])

    /**
     * Modifies the in-memory copy of an item with the matching ID. If no matching ID is found, it is added.
     *
     * @param item The item to modify or add
     */
    const modifyItem = (item: T) => {
        setItems(old => {
            let itemsCopy = [...old]

            processItem: {
                for (let i = 0; i < itemsCopy.length; i++) {
                    let oldItem = itemsCopy[i]
                    if (oldItem.id == item.id) {
                        itemsCopy[i] = item
                        break processItem
                    }
                }

                // No item has been found with the given ID
                itemsCopy.push(item)
            }

            return itemsCopy
        })
    }

    /**
     * Deletes an item matching the in-memory list of items. If no item is found with the given ID, nothing occurs.
     *
     * @param itemId The item to delete
     */
    const deleteItem = (itemId: string) => {
        setItems(old => {
            let itemsCopy = [...old]

            for (let i = 0; i < itemsCopy.length; i++) {
                if (itemsCopy[i].id == itemId) {
                    itemsCopy.splice(i, 1)
                    break
                }
            }

            return itemsCopy
        })
    }

    /**
     * Displays the contents of {@link PaginationOptions#nextPage} if {@link paginated} is `true`.
     */
    const nextPage = (): void => {
        if (paginated) {
            displayOptions.nextPage().then(setItems)
        }
    }

    /**
     * Displays the contents of {@link PaginationOptions#previousPage} if {@link paginated} is `true`.
     */
    const previousPage = (): void => {
        if (paginated) {
            displayOptions.previousPage().then(setItems)
        }
    }

    /**
     * The current item being dragged.
     */
    const [draggingItem, setDraggingItem] = useState<T | undefined>()

    /**
     * The offset of the mouse holding onto the handle to the dragged item, used for drag position calculations.
     */
    const [draggingOffset, setDraggingOffset] = useState({offsetX: 0, offsetY: 0})

    /**
     * The initial scroll of the window when dragging starts, to ensure the window can be scrolled when an item is being dragged.
     */
    const [initialScrollY, setInitialScrollY] = useState<number>(0)

    /**
     * The last time in milliseconds the mouse moved, to act as a cooldown for closest drop point calculations.
     */
    const [lastMouseMoveMs, setLastMouseMoveMs] = useState<number>(0)

    /**
     * A returned type when fetching the closest place to drop a dragged item.
     */
    type ClosestDropPoint = { item: T, leftSide: boolean }

    /**
     * The currently closest dropping point of the dragging item.
     */
    const [currentHover, setCurrentHover] = useState<ClosestDropPoint | undefined>()

    /**
     * Gets the placeholder and draggable {@link HTMLDivElement} of the given item.
     *
     * @param item The item to get the elements for. If undefined, undefined will be returned
     */
    const getDraggingElements = (item?: T): { placeholder: HTMLDivElement, draggable: HTMLDivElement } | undefined => {
        if (!item) return undefined
        if (!itemRefs.has(item)) return undefined
        let {draggableRef, placeholderRef} = itemRefs.get(item)!

        if (!placeholderRef.current || !draggableRef.current) return undefined
        return {placeholder: placeholderRef.current, draggable: draggableRef.current}
    }

    /**
     * Removes any right or left highlight class from the given item.
     *
     * @param item The item to remove the highlight for
     */
    const removeHighlight = (item: T): void => {
        itemRefs.get(item)?.draggableRef.current?.classList.remove('left-highlight', 'right-highlight')
    }

    /**
     * Sets the given item's position as the new index in the original list of {@link items}, via
     * {@link SingleListOptions.changeOrder}.
     *
     * @param item The item to change order
     * @param newIndex The new index of the item
     */
    const changeOrder = (item: T, newIndex: number) => {
        let singleOptions = displayOptions as SingleListOptions<T>
        let newItems = singleOptions.changeOrder!(item, newIndex, items)
        setItems(newItems)
    }

    /**
     * Handles the mouse up event when an item is being dragged. If applicable, the items are rearranged.
     *
     * @param event The mouse event
     */
    const onMouseUp = (event: React.MouseEvent<any>): void => {
        if (!draggingItem) return
        setDraggingItem(undefined)

        let {draggableRef, placeholderRef} = itemRefs.get(draggingItem)!
        if (!placeholderRef.current || !draggableRef.current) return
        let placeholder = placeholderRef.current
        let draggable = draggableRef.current

        placeholder.style.width = `0px`
        placeholder.style.height = `0px`

        draggable.style.width = ''
        draggable.style.height = ''
        draggable.style.left = ``
        draggable.style.top = ``

        draggable.classList.remove('dragging-item', 'high-z')
        placeholder.classList.remove('placeholder-show')

        if (currentHover != undefined) {
            const {item, leftSide} = currentHover
            let currentIndex = items.indexOf(draggingItem)
            let index = items.indexOf(item)
            index += (leftSide ? 0 : 1)

            if (index > currentIndex) {
                index--
            }

            if (index != currentIndex) {
                changeOrder(draggingItem, index)
            }

            removeHighlight(currentHover!.item)
        }
    }

    /**
     * Handles the mouse moving when dragging an item.
     *
     * @param event The mouse event
     */
    const onMouseMove = (event: React.MouseEvent<any>): void => {
        const {draggable} = getDraggingElements(draggingItem) ?? {}
        if (!draggable) return

        const {offsetX, offsetY} = draggingOffset

        draggable.style.left = `${event.clientX - offsetX}px`
        draggable.style.top = `${event.clientY - offsetY - (initialScrollY - window.scrollY)}px`

        let now = new Date().getTime()
        let diff = now - lastMouseMoveMs

        if (diff < 250) return
        setLastMouseMoveMs(now)

        let closest = getClosestItemTo(draggingItem!, event.clientX, event.clientY, draggable.clientHeight)
        if (!closest) return

        const {item, leftSide} = closest

        if (currentHover != undefined && currentHover != closest) {
            removeHighlight(currentHover!.item)
        }

        setCurrentHover(closest)

        itemRefs.get(item)?.draggableRef.current?.classList.add(leftSide ? 'left-highlight' : 'right-highlight')
    }

    /**
     * Takes an item being dragged and gets the closest applicable drop point. A drop point is an item's left or right
     * side.
     *
     * @param currentItem The item being dragged
     * @param x The x position of the mouse dragging the item
     * @param y The y position of the mouse dragging the item
     * @param height The height of the item being dragged
     */
    const getClosestItemTo = (currentItem: T, x: number, y: number, height: number): ClosestDropPoint | undefined => {
        let closestItem: T | undefined = undefined
        let closestDistance = Infinity
        let leftSide = false

        for (let [item, dragRef] of itemRefs) {
            let droppable = dragRef.draggableRef.current
            if (item == currentItem || !droppable) continue

            const rect = droppable.getBoundingClientRect()

            const distanceLeft = Math.abs(x - rect.left)
            const distanceRight = Math.abs(x - rect.right)
            let draggingMiddle = y + height / 2

            if (draggingMiddle > rect.top && draggingMiddle < rect.bottom) {
                if (distanceLeft < closestDistance) {
                    closestDistance = distanceLeft
                    closestItem = item
                    leftSide = true
                }

                if (distanceRight < closestDistance) {
                    closestDistance = distanceRight
                    closestItem = item
                    leftSide = false
                }
            }
        }

        if (closestItem == undefined) {
            return undefined
        }

        return {item: closestItem, leftSide: leftSide}
    }

    /**
     * Returns a {@link HandleEvent} object to handle mouse event invocations of the handle.
     * 
     * @param item The item the events are tied to
     */
    const registerItemHandle = (item: T): HandleEvent => (type, event) => {
        let draggingElements = getDraggingElements(item)
        if (!draggingElements) return
        const {placeholder, draggable} = draggingElements

        switch (type) {
            case 'mousedown':
                setDraggingItem(item)
                draggable.classList.add('interm', 'high-z')

                placeholder.style.width = `${draggable.clientWidth}px`
                placeholder.style.height = `${draggable.clientHeight}px`

                const rect = draggable.getBoundingClientRect();

                const x = rect.left + window.scrollX;
                const y = rect.top + window.scrollY;

                draggable.style.left = `${x}px`
                draggable.style.top = `${y}px`

                setInitialScrollY(window.scrollY)
                setDraggingOffset({
                    offsetX: event.clientX - draggable.offsetLeft,
                    offsetY: event.clientY - draggable.offsetTop
                })

                draggable.classList.remove('interm')
                draggable.classList.add('dragging-item')
                placeholder.classList.add('placeholder-show')
                break;
        }
    }

    /**
     * Renders the content of the lister, without a wrapping div.
     */
    const listerContent = (): JSX.Element => <>
        {addModalOptions && <>
            <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>{addModalOptions.buttonText}</button>
            {addModalOptions.modal(showModalState, modifyItem)}
        </>}

        <div className={classes(`items mt-3`, listClasses)}>
            {items.map(item => {
                // These two should always be the same, unless the developer messed up
                if (reorderable && displayItem.length == 4) {
                    let setHandle = registerItemHandle(item)
                    let displayReorderableItem = displayItem as DisplayReorderableItem<T>
                    let refs = itemRefs.get(item)
                    return <>
                        <div ref={refs?.placeholderRef} className="drag-placeholder"></div>
                        <div ref={refs?.draggableRef} className="drag-wrapper">{displayReorderableItem(item, modifyItem, deleteItem, setHandle)}</div>
                    </>
                } else {
                    let displayNormalItem = displayItem as DisplayItem<T>
                    return displayNormalItem(item, modifyItem, deleteItem)
                }
            })}
        </div>

        {paginated && <div className="mt-3">
            <Button onClick={previousPage} disabled={!displayOptions.hasPrevious}>Previous</Button>
            <span className="mx-3">Page {displayOptions.page}</span>
            <Button onClick={nextPage} disabled={!displayOptions.hasNext}>Next</Button>
        </div>}
    </>

    return (
        <>
            {(includeParent ?? true) ?
                <div className="Lister" onMouseMove={e => onMouseMove(e)} onMouseUp={e => onMouseUp(e)}>{listerContent()}</div> : listerContent()}
        </>
    )
}
