import React, {useContext, useEffect, useState} from 'react';
import './UserCard.scss';
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";
import {ElevatedUser} from "../../../../logic/objects";
import {capitalizeFirst} from "../../../../logic/utility";
import {usePopper} from "react-popper";
import {Role} from "../../../../logic/self";
import useForm from "../../../../logic/useForm";
import requestHandler from "../../../../contexts/RequestHandler";

/**
 * The properties for {@link UserCard}.
 */
interface UserCardProps {
    /**
     * The user being displayed/modified in the card.
     */
    user: ElevatedUser

    /**
     * A function implemented by the caller to replace the displayed user with the given {@link ElevatedUser}, to cause
     * a UI update without requesting a new list of users.
     */
    updateLocalUser: (user: ElevatedUser) => void

    /**
     * Deletes the given {@link ElevatedUser} in the UI.
     */
    deleteLocalUser: (user: ElevatedUser) => void
}

/**
 * A component to display and modify an individual {@link ElevatedUser}.
 */
export const UserCard = ({user, updateLocalUser, deleteLocalUser}: UserCardProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * The element being used as a root for the popup menu.
     */
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    /**
     * The element being popped up as a menu of actions.
     */
    const [popupElement, setPopupElement] = useState<HTMLDivElement | null>(null);

    /**
     * If the user is being edited.
     */
    const [editing, setEditing] = useState<boolean>(false);

    /**
     * If the popup menu is visible.
     */
    const [visible, setVisible] = useState<boolean>(false);

    /**
     * The created popper fields.
     *
     * @param styles     The styles to be applied to the popup element
     * @param attributes The attributes to be applied to the popup element
     * @param update     A function to update the popup element
     */
    const {styles, attributes, update} = usePopper(referenceElement, popupElement, {
        placement: 'bottom-start',
        modifiers: [
            {name: 'preventOverflow', enabled: true}
        ]
    });

    /**
     * Invokes a {@link update} when visibility is changed. This is so the popup element has a valid initial state when
     * becoming visible.
     */
    useEffect(() => {
        update?.call(this)
    }, [visible])

    /**
     * Toggles the editing status of the user.
     */
    const toggleEdit = (): void => {
        setEditing(old => !old)
    }

    /**
     * Stops editing the user.
     */
    const stopEditing = (): void => {
        setEditing(false)
    }

    /**
     * Sends a password reset email and forces the user to reset their password.
     */
    const resetPassword = (): void => {
        requestHandler.request(`/auth/reset_password/request`, {
            method: 'POST', body: JSON.stringify({
                email: user.email
            })
        }).then(res => {
            if (res.status == 200) {
                toastHandler.addToast(new ToastInfo(`Reset ${user.firstName}'s password`, `Successfully reset the password for ${user.firstName}. They will recieve an email with furthewr instructions.`))
            }
        })
    }

    /**
     * Resets the user's 2FA, then invoking {@link updateLocalUser}.
     */
    const reset2FA = (): void => {
        requestHandler.request(`/api/user/${user.id}/reset_mfa`, {method: 'POST'})
            .then(async res => {
                if (res.status == 200) {
                    toastHandler.addToast(new ToastInfo(`Reset 2FA for ${user.firstName}`, `Successfully reset 2FA for ${user.firstName}.`))
                }

                let json = await res.json()
                updateLocalUser(json as ElevatedUser)
            })
    }

    /**
     * Disables the user, then invoking {@link updateLocalUser}.
     */
    const disableUser = (): void => {
        requestHandler.request(`/api/user/${user.id}`, {
            method: 'POST', body: JSON.stringify({
                accountStatus: 'DISABLED'
            })
        }).then(async res => {
            if (res.status == 200) {
                toastHandler.addToast(new ToastInfo(`Disabled ${user.firstName}'s account`, `Successfully disabled ${user.firstName}'s account.`))
            }

            let json = await res.json()
            updateLocalUser(json as ElevatedUser)
        })
    }

    /**
     * Sets a user's {@link ElevatedUser.accountStatus} to `ENABLED`, then invoking {@link updateLocalUser}.
     */
    const enableUser = (): void => {
        requestHandler.request(`/api/user/${user.id}`, {
            method: 'POST', body: JSON.stringify({
                accountStatus: 'ENABLED'
            })
        }).then(async res => {
            if (res.status == 200) {
                toastHandler.addToast(new ToastInfo(`Enabled ${user.firstName}'s account`, `Successfully enabled ${user.firstName}'s account.`))
            }

            let json = await res.json()
            updateLocalUser(json as ElevatedUser)
        })
    }

    /**
     * Sends a delayed registration invitation email, then invoking {@link updateLocalUser}.
     */
    const sendEmail = (): void => {
        requestHandler.request(`/api/auth/registration/send_emails`, {
            method: 'POST', body: JSON.stringify({
                users: [user.id]
            })
        }).then(async res => {
            if (res.status == 200) {
                toastHandler.addToast(new ToastInfo(`Invited ${user.firstName}`, `Successfully sent a registration email to ${user.firstName}.`))
            }

            let json = await res.json()
            updateLocalUser(json as ElevatedUser)
        })
    }

    /**
     * Deletes the user.
     *
     * TODO: Confirmation modal
     */
    const deleteUser = (): void => {
        requestHandler.request(`/api/user/${user.id}`, {method: 'DELETE'})
            .then(res => {
                if (res.status == 200) {
                    toastHandler.addToast(new ToastInfo(`Deleted ${user.firstName}`, `Successfully deleted ${user.firstName}.`))
                }

                deleteLocalUser(user)
            })
    }

    return (
        <div className="UserCard card col-12" key={user.id}>
            <div className="position-absolute d-flex justify-content-end w-100">
                <div className="p-1 popup-container" ref={setReferenceElement} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
                        <span className="more-dots material-symbols-outlined p-1">more_vert</span>

                    {visible &&
                        <div ref={setPopupElement} className="popup btn-group-vertical bg-white" role="group" aria-label="Vertical button group" style={styles.popper} {...attributes.popper}>

                            <button type="button" className="btn btn-outline-info" onClick={() => toggleEdit()}>{editing ? 'Stop Editing' : 'Edit'}</button>

                            {user.registerStatus == 'REGISTERED' &&
                                <button type="button" className="btn btn-outline-info" onClick={() => resetPassword()}>Reset
                                    Password</button>
                            }

                            <button type="button" className="btn btn-outline-info" onClick={() => reset2FA()}>Reset
                                    2FA</button>

                            {user.registerStatus == 'EMAIL_DELAYED' &&
                                <button type="button" className="btn btn-outline-info me-2" onClick={() => sendEmail()}>Send
                                    Registration Email</button>
                            }

                            {user.registerStatus == 'EMAIL_SENT' &&
                                <button type="button" className="btn btn-outline-info me-2" onClick={() => sendEmail()}>Resend
                                    Registration Email</button>
                            }

                            {user.accountStatus == 'ENABLED' &&
                                <button type="button" className="btn btn-outline-warning" onClick={() => disableUser()}>Disable</button>
                            }

                            {user.accountStatus == 'DISABLED' &&
                                <button type="button" className="btn btn-outline-info" onClick={() => enableUser()}>Enable</button>
                            }

                            <button type="button" className="btn btn-outline-danger" onClick={() => deleteUser()}>Delete</button>
                        </div>}
                </div>
            </div>
            <div className="card-body">
                {editing ? <EditComponent user={user} updateUser={updateLocalUser} stopEditing={stopEditing}/> : <DisplayComponent user={user}/>}
            </div>
        </div>
    )
}

/**
 * The properties for the card body component to display user information.
 */
interface UserDisplayProps {
    /**
     * The user being displayed in the card.
     */
    user: ElevatedUser
}

/**
 * A component to be rendered in the user card body, of non-editing data.
 */
const DisplayComponent = ({user}: UserDisplayProps): JSX.Element => {
    return (
        <>
            <h5 className="card-title">{user.firstName} {user.lastName}</h5>
            {user.displayName != undefined &&
                <h6 className="card-subtitle mb-2 text-muted">AKA: {user.displayName}</h6>}
            <h6 className="card-subtitle mb-2 text-muted">{user.roles.map(capitalizeFirst).join(', ')}</h6>

            {user.accountStatus == 'ENABLED' &&
                <p className="mb-1">
                    <span className="account-enabled material-symbols-outlined inline-icon me-2">done</span>
                    <span>Account enabled</span></p>
            }

            {user.accountStatus == 'DISABLED' &&
                <p className="mb-1">
                    <span className="account-disabled material-symbols-outlined inline-icon me-2">close</span>
                    <span>Account disabled</span></p>
            }

            {user.registerStatus == 'REGISTERED' &&
                <p className="mb-1">
                    <span className="register-complete material-symbols-outlined inline-icon me-2">how_to_reg</span>
                    <span>User registered</span></p>
            }

            {user.registerStatus == 'EMAIL_SENT' &&
                <p className="mb-1">
                    <span className="register-email-sent material-symbols-outlined inline-icon me-2">outgoing_mail</span>
                    <span>Email sent</span></p>
            }

            {user.registerStatus == 'EMAIL_DELAYED' &&
                <p className="mb-1">
                    <span className="register-email-delayed material-symbols-outlined inline-icon me-2">schedule_send</span>
                    <span>Email delayed</span></p>
            }

            {user.toptConfirmed &&
                <p className="mb-2">
                    <span className="topt-confirmed material-symbols-outlined inline-icon me-2">vpn_key</span>
                    <span>2FA Confirmed</span></p>
            }

            {!user.toptConfirmed &&
                <p className="mb-2">
                    <span className="topt-unconfirmed material-symbols-outlined inline-icon me-2">vpn_key_off</span>
                    <span>2FA Unconfirmed</span></p>
            }

            <div className="d-flex flex-row justify-content-end"></div>
        </>
    )
}


/**
 * The properties for the card body component to edit user information.
 */
interface UserEditProps {
    /**
     * The user being displayed/modified in the card.
     */
    user: ElevatedUser

    /**
     * A function implemented by the caller to replace the displayed user with the given {@link ElevatedUser}, to cause
     * a UI update without requesting a new list of users.
     */
    updateUser: (user: ElevatedUser) => void

    /**
     * Switches from {@link EditComponent} to {@link DisplayComponent} to being displayed, throwing away any data being
     * modified.
     */
    stopEditing: () => void
}

/**
 * The inputs that are mapped to the form by an input's name. These are for setting initial user details.
 * This is initialized by {@link useForm}.
 */
type UserFormInputs = {
    /**
     * The user's first name.
     */
    firstName: string

    /**
     * The user's last name.
     */
    lastName: string

    /**
     * An optional display name for the user, used for DJs.
     */
    displayName: string

    /**
     * The user's email.
     */
    email: string

    /**
     * The roles the user has. These are used as permissions.
     */
    roles: string[]
}

/**
 * A component to be rendered in the user card body, of data being edited.
 */
const EditComponent = ({user, updateUser, stopEditing}: UserEditProps): JSX.Element => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * A hook for processing form data.
     *
     * @param handleSubmit A function to process submitted a form
     */
    const {handleSubmit} = useForm<UserFormInputs>()

    /**
     * Starts the registration of a {@link User} based on its given form inputs. This is invoked as a callback to
     * {@link handleSubmit}.
     *
     * @param inputs The form data corresponding to inputs with the same name as each field
     */
    const processSubmit = async (inputs: UserFormInputs): Promise<void> => {
        requestHandler.request(`/api/user/${user.id}`, {method: 'POST', body: JSON.stringify(inputs)})
            .then(async res => {
                if (res.status == 200) {
                    toastHandler.addToast(new ToastInfo(`Updated ${user.firstName}`, `Successfully updated the data for ${inputs.firstName}.`))
                    let json = await res.json()
                    updateUser(json as ElevatedUser)

                    stopEditing()
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while updating the user. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }
            })
    }

    return (
        <form onSubmit={e => handleSubmit(e, processSubmit)} autoComplete="off">
            <label htmlFor="firstName" className="mb-1">First name</label>
            <input className="form-control mb-1" name="firstName" defaultValue={user.firstName} placeholder="First name"/>

            <label htmlFor="lastName" className="mb-1">Last name</label>
            <input className="form-control mb-2" name="lastName" defaultValue={user.lastName} placeholder="Last name"/>

            <label htmlFor="displayName" className="mb-1">Display name</label>
            <input className="form-control mb-3" name="displayName" defaultValue={user.displayName} placeholder="Display name"/>

            <label htmlFor="email" className="mb-1">Email</label>
            <input className="form-control mb-2" name="email" defaultValue={user.email} placeholder="Email"/>

            <label htmlFor="roles" className="mb-1">Roles</label>
            <select id="roles" className="form-select mb-2" name="roles" multiple aria-label="Select roles" defaultValue={user.roles}>
                {Role.values.map(role =>
                    <option value={role.name} key={role.name}>{capitalizeFirst(role.name)}</option>)}
            </select>

            <div className="d-flex flex-row justify-content-end">
                <button className="btn btn-primary me-2" type="submit">Save</button>
                <button className="btn btn-secondary" onClick={() => stopEditing()}>Cancel</button>
            </div>
        </form>
    )
}
