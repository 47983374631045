/**
 * The base URL that requests are sent to.
 */
import React, {createContext, useContext, useEffect, useState} from "react";
import {Result, statusToResult} from "../logic/requests";
import {Self} from "../logic/self";
import {ToastHandler, ToastHandlerContext, ToastInfo} from "./ToastHandler";
import {State} from "../logic/react_utility";
import {executeCaptcha} from "../logic/recaptcha";

const REQUEST_URL = process.env.REACT_APP_BACKEND_API_URL ?? ''
const TOKEN_EXPIRY_MS = 15 * 60 * 1000 // 15 minutes
const TOKEN_REFRESH_INTERVAL_MS = TOKEN_EXPIRY_MS - (60 * 1000) // 14 minutes

/**
 * Data a request may need, in addition to {@link RequestInit}.
 */
interface AdditionalRequestInit {
    /**
     * If the `Content-Type` header should be set by the browser. If `false` and no header is supplied, it will default
     * to `application/json`.
     */
    autoContentType?: boolean

    /**
     * If Google reCAPTCHA should be attempted before making the request. If `true`, this will include a `Captcha-Token`
     * header with the resulting token to be verified by the server.
     */
    includeCaptcha?: boolean
}

/**
 * A handler for all API requests.
 */
export class RequestHandler {

    /**
     * The toast handler, for giving error statuses on requests.
     */
    private toastHandler: ToastHandler

    /**
     * If the user is logged in. This is determined by the responses of authenticated requests (including automatic
     * token refreshing).
     */
    readonly loggedIn: boolean

    /**
     * Sets the {@link loggedIn} state.
     */
    private readonly setLoggedInState: React.Dispatch<React.SetStateAction<boolean>>

    /**
     * The last time in milliseconds the JWT token was refreshed.
     */
    private lastRefreshTime: number = -1

    /**
     * The current user
     */
    readonly self: Self | undefined
    private readonly setSelf: React.Dispatch<React.SetStateAction<Self | undefined>>

    constructor(loggedInState: State<boolean>, selfState: State<Self | undefined>, toastHandler: ToastHandler) {
        this.toastHandler = toastHandler;
        [this.loggedIn, this.setLoggedInState] = loggedInState;
        [this.self, this.setSelf] = selfState;
    }

    /**
     * Sets the {@link loggedIn} status to the parameter given. If `false`, {@link self}
     *
     * @param loggedIn If the user should be marked as logged in
     */
    setLoggedIn(loggedIn: boolean): Promise<void> {
        if (loggedIn) {
            return this.updateSelf()
                .then(() => this.setLoggedInState(true))
        } else {
            this.setSelf(undefined)
            this.setLoggedInState(false)
            return Promise.resolve()
        }
    }

    /**
     * Begins a loop of refreshing the refresh token every {@link TOKEN_REFRESH_INTERVAL_MS} milliseconds.
     */
    beginRefreshCycle(): void {
        // This is the interval the loop is set at, but the actual time until refresh is {@link TOKEN_REFRESH_INTERVAL_MS}.
        const interval = 15000

        this.refreshToken()
        setInterval(() => {
            let now = new Date().getTime()
            let timeDiffMs = now - this.lastRefreshTime
            let remainingMsAbs = Math.abs(timeDiffMs - TOKEN_REFRESH_INTERVAL_MS)
            if (this.lastRefreshTime == -1 || remainingMsAbs <= interval) {
                this.refreshToken()
            }
        }, interval)
    }

    /**
     * Makes a request to refresh the JWT.
     */
    private refreshToken(): Promise<void> {
        console.debug('Refreshing token!');
        this.lastRefreshTime = new Date().getTime()
        return fetch(`${REQUEST_URL}/api/auth/refresh`, {method: 'POST'})
            .then(res => {
                this.setLoggedIn(res.status == 200);
            })
    }

    /**
     * Updates {@link self} with the currently authenticated user.
     */
    private updateSelf(): Promise<void> {
        return this.request('/api/account/me', {method: 'GET'})
            .then(async res => {
                let json = await res.json()
                this.setSelf(new Self(json))
            })
    }

    /**
     * Performs an authenticated request.
     *
     * @param path The request API path, with a leading `/`
     * @param init Request details
     * @return The HTTP response
     */
    request(path: string, init?: RequestInit & AdditionalRequestInit | undefined): Promise<Response> {
        return this.attemptAuthedRequest(path, init)
    }

    /**
     * Resets JWT and refresh tokens.
     */
    logOut(): Promise<void> {
        return this.request('/api/auth/logout', {method: 'POST'})
            .then(() => this.setLoggedIn(false))
    }

    /**
     * Requests to log in with the given credentials.
     *
     * @param email    The email of the user
     * @param password The password of the user
     * @param toptCode The 2FA code
     * @return The response of the login
     */
    async requestLogin(email: string, password: string, toptCode: string): Promise<Result> {
        return this.attemptAuthedRequest('/api/auth/login', {
            method: 'POST', body: JSON.stringify({
                email: email,
                password: password,
                toptCode: toptCode
            })
        }, false, false).then(res => {
            this.setLoggedIn(res.status == 200);
            return statusToResult(res.status)
        }, res => statusToResult(res.status))
    }

    /**
     * Attempts to make an authenticated HTTP request.
     *
     * @param path        The request API path, with a leading `/`
     * @param init        Request details
     * @param retried     If the request has already been retried. If `true`, upon request failure it will set the user
     *                    as not logged in and reject the result promise. Otherwise, it will attempt the request again.
     * @param autoRefresh If the user should have their token refreshed before retrying on an unauthorized failure
     * @return The request response
     */
    private async attemptAuthedRequest(path: string, init: RequestInit & AdditionalRequestInit | undefined, retried: boolean = false, autoRefresh: boolean = true): Promise<Response> {

        /**
         * Retries a previously tried request. If {@link retried} is already `true`, {@link loggedIn} is set to `false`
         * and the promise is rejected. Requests may only be retried once.
         *
         * @param lastResponse The previous response, to be sent in the case of immediate rejection
         * @return The new authed request (or a rejection if this has already been retried)
         */
        const retry = (lastResponse: Response): Promise<Response> => {
            if (retried) {
                console.debug('Rejecting, cant refresh token');
                this.setLoggedIn(false)
                return Promise.reject(lastResponse);
            }

            return this.attemptAuthedRequest(path, init, true)
        }


        let headers = new Headers(init?.headers)
        if (!(init?.autoContentType ?? false)) {
            headers.set('Content-Type', 'application/json')
        }

        if (init?.includeCaptcha ?? false) {
            await executeCaptcha().then(token => headers.set('Captcha-Token', token))
        }

        return fetch(REQUEST_URL + path, {...init, headers: headers})
            .then(async res => {
                if (autoRefresh && res.status == 401) {
                    console.debug('401, refreshing token');
                    return this.refreshToken().then(() => retry(res))
                }

                if (res.status >= 500 && res.status < 600) {
                    this.toastHandler.addToast(new ToastInfo(`Internal Server Error`, `An Internal Server Error occurred, status code ${res.status}.`, ToastInfo.COLOR_RED))
                    console.error("500 ISE")
                    console.error(await res.body);
                }

                return res;
            })
    }

}

/**
 * A context to provide a singleton {@link RequestHandler}
 */
export const RequestHandlerContext = createContext<RequestHandler>({} as RequestHandler)

/**
 * The properties for {@link RequestHandlerProvider}.
 */
interface RequestHandlerProviderProps {
    /**
     * The children of the provider.
     */
    children: JSX.Element
}

/**
 * A component returning a provider to provide a singleton {@link RequestHandler}.
 */
export const RequestHandlerProvider = ({children}: RequestHandlerProviderProps) => {
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * If the client is logged in.
     */
    const loggedInState = useState<boolean>(() => sessionStorage.getItem('self') != null)

    /**
     * The current reference to {@link Self} for the logged-in user.
     */
    const selfState = useState<Self | undefined>(() => {
        let selfJson = sessionStorage.getItem('self')
        if (selfJson == null) {
            return undefined
        }

        return new Self(JSON.parse(selfJson))
    })

    /**
     * The single request handler to handle all requests across all components/services.
     */
    const [requestHandler, setRequestHandler] = useState<RequestHandler>(() => new RequestHandler(loggedInState, selfState, toastHandler))

    /**
     * Initially begins the JWT refresh cycle.
     */
    useEffect(() => {
        requestHandler.beginRefreshCycle()
    }, [])

    /**
     * Updates the {@link requestHandler} with new logged in state and self state when they change.
     */
    useEffect(() => {
        let self = selfState[0]
        if (self) {
            sessionStorage.setItem('self', self.toJson())
        } else {
            sessionStorage.removeItem('self')
        }

        setRequestHandler(new RequestHandler(loggedInState, selfState, toastHandler))
    }, [loggedInState[0], selfState[0]])

    return (
        <RequestHandlerContext.Provider value={requestHandler}>
            {children}
        </RequestHandlerContext.Provider>
    )
}

export default RequestHandlerContext
