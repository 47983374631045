import {getDisplayName} from "./utility";

/**
 * A type that can be listed.
 */
export type Listable = {

    /**
     * The unique ID of the object.
     */
    id: string
}

/**
 * Information regarding an event. An event is something that may happen 0+ times, such as an AMM, picnic, listening
 * session, etc.
 */
export interface EventDescription {
    /**
     * The ID of the event.
     */
    id: string

    /**
     * The name of the event.
     */
    name: string

    /**
     * A description of the event.
     */
    description: string

    /**
     * The users who own this event.
     */
    owners: User[]
}

/**
 * Data for creating or modifying an event. An event is something that may happen 0+ times, such as an AMM, picnic,
 * listening session, etc. This has no time information associated with it, which is where {@link EventTimeslot}s are
 * used.
 */
export interface EventCreate {
    /**
     * The name of the event being created.
     */
    name: string

    /**
     * A description of the event being created.
     */
    description: string

    /**
     * An array of users' UUIDs that own this event.
     */
    owners: string[]
}

/**
 * A basic view of a registered user.
 */
export interface User extends Listable {
    /**
     * The user's UUID.
     */
    id: string

    /**
     * The user's first name.
     */
    firstName: string

    /**
     * The user's last name.
     */
    lastName: string

    /**
     * An optional display name for the user, used for DJs.
     */
    displayName?: string
}

/**
 * An enum to determine if a user may log into their account or not. This is separate from {@link RegisterStatus},
 * meaning a user still might not be able to log in even if the {@link AccountStatus} is `ENABLED`.
 */
type AccountStatus = 'ENABLED' | 'DISABLED'

/**
 * The completion status of a user's registration.
 */
type RegisterStatus = 'EMAIL_DELAYED' | 'EMAIL_SENT' | 'REGISTERED'

/**
 * All information available for a user, only returned at authorized endpoints.
 */
export interface ElevatedUser extends Listable {
    /**
     * The user's UUID.
     */
    id: string

    /**
     * The user's email.
     */
    email: string

    /**
     * The user's first name.
     */
    firstName: string

    /**
     * The user's last name.
     */
    lastName: string

    /**
     * An optional display name for the user, used for DJs.
     */
    displayName?: string

    /**
     * The roles/permissions the user has.
     */
    roles: string[]

    /**
     * The status regarding the account being disabled by administration.
     */
    accountStatus: AccountStatus

    /**
     * The completion status of the registration.
     */
    registerStatus: RegisterStatus

    /**
     * If the user's 2FA has been confirmed.
     */
    toptConfirmed: boolean
}

/**
 * Information regarding a DJ show.
 */
export interface ShowDescription {
    /**
     * The UUID of the show.
     */
    id: string

    /**
     * The name of the show.
     */
    name: string

    /**
     * A description of the show.
     */
    description: string

    /**
     * A URL of the show's cover image.
     */
    image: string

    /**
     * A string describing when this show is on air. For example, "Tuesdays 3-4pm".
     */
    time: string

    /**
     * The owners of the show.
     */
    owners: User[]
}

/**
 * Data to create or modify a DJ show.
 */
export interface ShowCreate {

    /**
     * The name of the show.
     */
    name: string

    /**
     * A description of the show.
     */
    description: string

    /**
     * A URL of the show's cover image.
     */
    image: string

    /**
     * A string describing when this show is on air. For example, "Tuesdays 3-4pm".
     */
    time: string

    /**
     * An array of UUIDs of the owners of the show.
     */
    owners: string[]
}

/**
 * A response object to display previously requested music (posted through MusicRequest originally).
 */
export interface MusicRequest {
    /**
     * The UUID of the request.
     */
    id: string

    /**
     * The name of the requester.
     */
    name: string

    /**
     * The email of the requester.
     */
    email: string

    /**
     * The title of the song being requested.
     */
    title: string

    /**
     * TheThe artist of the song being requested.
     */
    author: string
}

/**
 * Simple information on a track, to be displayed in things like the top 5 tracks of the month
 */
export interface BasicTrack {
    /**
     * The track UUID.
     */
    id: string

    /**
     * The name of the track.
     */
    name: string

    /**
     * The artist of the track's name.
     */
    artist: string

    /**
     * The album art URL of the track.
     */
    image: string
}

/**
 * Data for creating a {@link BasicTrack}.
 */
export interface BasicTrackCreate {
    /**
     * The name of the song.
     */
    name: string

    /**
     * The artist of the song.
     */
    artist: string

    /**
     * The album art URL of the track. If this is unset, it is automatically looked up.
     */
    image: string

    /**
     * The order (descending) to sort all tracks by.
     */
    order: number
}

/**
 * A department a {@link Staff} can be in.
 */
export type Department = 'engineering' | 'programming' | 'recording' | 'general' | 'business'

/**
 * A list of all {@link Department}s.
 */
export const Departments: Department[] = ['engineering', 'programming', 'recording', 'general', 'business']

/**
 * A staff member's contact information.
 */
export interface Staff extends Listable {

    /**
     * The staff member's UUID.
     */
    id: string

    /**
     * The name of the staff member.
     */
    name: string

    /**
     * The staff's email.
     */
    email: string

    /**
     * The staff's position.
     */
    position: string

    /**
     * The department the staff is in.
     */
    department: Department

    /**
     * An image URL for the staff member.
     */
    image: string

    /**
     * An optional image URI to be displayed when a staff member is hovered over.
     */
    secondaryImage: string

    /**
     * The order number of the staff member.
     */
    order: number

    /**
     * If the staff member is part of eboard.
     */
    eboard: boolean
}

/**
 * Data for creating or modifying a {@link Staff}'s contact info. If creating, ALL fields are required.
 */
export interface StaffCreate {
    /**
     * The name of the staff member.
     */
    name?: string

    /**
     * The staff's email.
     */
    email?: string

    /**
     * The staff's position.
     */
    position?: string

    /**
     * The department the staff is in.
     */
    department?: Department

    /**
     * An image URL for the staff member.
     */
    image?: string

    /**
     * An optional image URI to be displayed when a staff member is hovered over.
     */
    secondaryImage?: string

    /**
     * The order number of the staff member.
     */
    order?: number

    /**
     * If the staff member is part of eboard.
     */
    eboard?: boolean
}

/**
 * An object sent in a request to change the order of a {@link Staff} by their ID.
 */
export interface StaffOrder {
    /**
     * The UUID of a staff member.
     */
    id: string

    /**
     * The order number, relative to all other numbers.
     */
    order: number
}

/**
 * A collection of objects that may be accessed through pages.
 */
export interface Paginated<T> {
    /**
     * The items in the current page.
     */
    items: T[]

    /**
     * Links to navigate the pages.
     */
    _links: PageLinks
}

/**
 * Links to navigate a {@link Paginated}.
 */
export interface PageLinks {
    /**
     * A link to the next page, if there is one.
     */
    next: string | undefined

    /**
     * A link to the previous page, if there is one.
     */
    previous: string | undefined
}

/**
 * A {@link Post} but with the parent show included. This is used for context-free listing of all posts.
 */
export interface DetailedPost extends Post {
    /**
     * The parent show.
     */
    show: ShowDescription
}

/**
 * A post from a show. This is created by an owner of the show, and contains markdown formatted content. This will
 * probably be expanded in the future.
 */
export interface Post {
    /**
     * The post UUID.
     */
    id: string

    /**
     * The title of the post.
     */
    title: string

    /**
     * The content of the post.
     */
    content: string

    /**
     * The time in milliseconds this was published.
     */
    posted: number

    /**
     * An optional cover image URL.
     */
    image: string

    /**
     * The authors of the post.
     */
    authors: User[]
}

/**
 * Data for creating or modifying a DJ show's post.
 */
export interface PostCreate {
    /**
     * The title of the post.
     */
    title: string

    /**
     * The markdown content of the post.
     */
    content: string

    /**
     * The time in milliseconds this was posted.
     */
    posted: number

    /**
     * An optional image URL for the post.
     */
    image: string

    /**
     * A list of user UUIDs that should be labeled as authors on the post in addition to the show's owners.
     */
    additionalAuthors: string[]
}

/**
 * Any information the frontend may need regarding registering a user (generally from a link in an email).
 */
export interface RegistrationCompletionInfo {
    /**
     * The email of the registering user.
     */
    email: string

    /**
     * The user's first name.
     */
    firstName: string

    /**
     * 2FA information.
     */
    topt: TOPTInitialInfo | undefined
}

/**
 * Initial information sent when setting 2FA.
 */
export interface TOPTInitialInfo {
    /**
     * The data URI of the QR code image.
     */
    qrCode: string

    /**
     * The raw secret the QR code was derived from.
     */
    secret: string
}

/**
 * Gets the combined first and last name of the {@link User}.
 *
 * @param user The user to get the names of
 * @return The combined name
 */
export function getFullName(user: User): string {
    return `${user.firstName} ${user.lastName}`
}

/**
 * Combines a list of {@link User}'s names into a comma-separated string, with `, and` between the second to last and
 * last user if the list's length is >2.
 *
 * @param users The list of users to combine
 * @return The combined string
 */
export function combineNames(users: User[]): string {
    if (users.length == 1) {
        return getDisplayName(users[0])
    }

    if (users.length == 2) {
        return `${getDisplayName(users[0])} and ${getDisplayName(users[1])}`
    }

    let str = ''
    for (let i = 0; i < users.length; i++) {
        if (i != 0) {
            if (i == users.length - 1) {
                str += ', and '
            } else {
                str += ', '
            }
        }

        str += getDisplayName(users[i])
    }

    return str
}
