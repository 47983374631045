import {createContext, useState} from "react";
import {Track, TrackBroadcast, TrackReceiver} from "@witr-radio/music-logger-service";
import {WEBSOCKET_URL} from "../component/App";

/**
 * A consumer accepting a TrackBroadcast from a server sent through a websocket.
 *
 * @param trackBroadcast The track being consumed
 */
type TrackListener = (trackBroadcast: TrackBroadcast) => void

/**
 * A consumer invoked when the underground status of {@link TrackMultiReceiver} changes through
 * {@link TrackMultiReceiver#setUnderground}.
 *
 * @param underground If the receivers are now listening to underground
 */
type UndergroundListener = (underground: boolean) => void

/**
 * A context-provided class that allows for multiple listeners on a single `TrackReceiver` listening for tracks.
 * Listeners may be added or removed, indexed by an ID.
 */
export class TrackMultiReceiver {

    /**
     * The single TrackReceiver that actually receives all tracks, initialized in the constructor.
     */
    readonly trackReceiver: TrackReceiver

    /**
     * All listeners that are invoked whenever the {@link TrackReceiver} receives a track.
     */
    private readonly broadcastListeners: Map<number, TrackListener> = new Map<number, TrackListener>()

    /**
     * All listeners that are invoked whenever {@link underground} is changed.
     */
    private readonly undergroundListeners: Map<number, UndergroundListener> = new Map<number, UndergroundListener>()

    /**
     * The currently playing track
     */
    currentTrack: Track | undefined

    /**
     * The last ID used for identifying listeners.
     */
    lastId: number = 0

    /**
     * If the receiver is taking tracks from underground.
     */
    underground: boolean = false

    constructor() {
        this.trackReceiver = new TrackReceiver(WEBSOCKET_URL, this.underground, trackBroadcast => {
            this.currentTrack = trackBroadcast.track
            this.broadcastListeners.forEach(listener => listener(trackBroadcast))
        })
    }

    /**
     * Adds a listener to be invoked whenever a track is received.
     *
     * @param listener The listener to add
     * @return The ID of the listener, used for removing
     */
    addListener(listener: TrackListener): number {
        this.broadcastListeners.set(++this.lastId, listener)
        return this.lastId
    }

    /**
     * Adds a listener to be invoked whenever a track is received.
     *
     * @param listener The listener to add
     * @return The ID of the listener, used for removing
     */
    addUndergroundListener(listener: UndergroundListener): number {
        this.undergroundListeners.set(++this.lastId, listener)
        return this.lastId
    }

    /**
     * Removes the given listener IDs. This works for both {@link TrackListener}s and {@link UndergroundListener}s.
     *
     * @param listenerId The ID of the listener to remove
     */
    removeListener(...listenerId: number[]): void {
        listenerId.forEach(id => {
            this.broadcastListeners.delete(id)
            this.undergroundListeners.delete(id)
        })
    }

    /**
     * Sets if the receiver should switch to underground.
     *
     * @param underground If the received tracks should be from underground
     * @return The success status of connecting to the new websocket. If the underground status didn't change, the
     *         return will be `false` and no listener
     */
    async setUnderground(underground: boolean): Promise<boolean> {
        this.underground = underground
        let status = await this.trackReceiver.setUnderground(underground)
        this.undergroundListeners.forEach(listener => listener(underground))
        return status
    }
}

/**
 * A context to provide a `TrackMultiReceiver` to wherever the context is used. The context's value must be initialized
 * immediately.
 */
const TrackMultiReceiverContext = createContext<TrackMultiReceiver>({} as TrackMultiReceiver)

export default TrackMultiReceiverContext
