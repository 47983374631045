import React from 'react';
import './DJPanel.scss';
import {Link} from "react-router-dom";

/**
 * The DJ panel, with links to go to DJ-specific things.
 */
export const DJPanel = () => {
    return (
        <div className="DJPanel">
            <h2>DJ Panel</h2>

            <Link to="/dj/top">Weekly Chart</Link>
            <Link to="/dj/broadcastListeners/studio-x">Streaming Listeners: Studio X</Link>
            <Link to="/dj/broadcastListeners/studio-a">Streaming Listeners: Studio A</Link>
            <Link to="/dj/bible">Vinyl Bible</Link>
            <Link to="/dj/requests">Requests</Link>
            <Link to="/dj/support">Support Ticket</Link>
        </div>
    );
}
