import React, {useContext, useEffect, useState} from 'react';
import './TopSongs.scss';
import '../Sidebar.scss'
import {ArtworkDisplay} from "../../portable/artwork_display/ArtworkDisplay";
import {BasicTrack} from "../../../logic/objects";
import RequestHandlerContext from "../../../contexts/RequestHandler";

/**
 * A component to be used in the Sidebar to display the top X songs.
 */
export const TopSongs = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The ordered top tracks being displayed.
     */
    let [tracks, setTracks] = useState<BasicTrack[]>([])

    /**
     * Fetches the top tracks.
     */
    useEffect(() => {
        requestHandler.request('/api/music/top')
            .then(async res => {
                let json = await res.json()
                setTracks(json as BasicTrack[])
            })
    }, [])

    return (
        <div className="TopSongs sidebar-component">
            <h3 className="title">Top 5 Weekly</h3>
            <ol>
                {tracks.map(track =>
                    <ArtworkDisplay alt="Artwork" key={track.id} image={track.image} primaryText={track.name} secondaryText={track.artist}/>
                )}
            </ol>
        </div>
    );
}
