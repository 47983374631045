import {ChangeEvent, FormEvent, useContext, useState} from 'react';
import RequestHandlerContext from "../contexts/RequestHandler";
import {ElevatedUser, Listable, PageLinks} from "./objects";
import {PaginationOptions} from "../component/portable/lister/Lister";

/**
 * A hook for handling paginated requests.
 *
 * @param initialUrl The URL to request to fetch the initial items on the first page. Subsequent pages will use the
 *                   links in the response of this request.
 */
export default function usePaginatedFetcher<T extends Listable>(initialUrl: string): PaginationOptions<T> {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The previous/next links generated by the most recent listing request.
     */
    const [links, setLinks] = useState<PageLinks | undefined>()

    /**
     * The most recent link requested, used for refreshing the current page.
     */
    const [lastLink, setLastLink] = useState<string>(initialUrl)

    /**
     * The current page being shown, 1-indexed.
     */
    const [page, setPage] = useState<number>(1)

    /**
     * Fetches items using the paginated URL given.
     *
     * @param url The request URL, by default {@link lastLink}. If this is the first invocation, {@link initialUrl} is
     *            used.
     */
    const fetchItems = (url: string = lastLink): Promise<T[]> => {
        setLastLink(url)
        return requestHandler.request(url)
            .then(async res => {
                let json = await res.json()
                setLinks(json._links as PageLinks)
                return json.items as T[]
            })
    }

    /**
     * Gets the items in the next page. If the page is unavailable, an empty list is returned.
     */
    const nextPage = (): Promise<T[]> => {
        if (links?.next == undefined) {
            return Promise.resolve([])
        }

        setPage(old => old + 1)
        return fetchItems(links?.next)
    }

    /**
     * Gets the items in the previous page. If the page is unavailable, an empty list is returned.
     */
    const previousPage = (): Promise<T[]> => {
        if (links?.previous == undefined) {
            return Promise.resolve([])
        }

        setPage(old => old - 1)
        return fetchItems(links?.previous)
    }

    return {
        initialItems: fetchItems,
        nextPage: nextPage,
        previousPage: previousPage,
        page: page,
        hasNext: links?.next != undefined,
        hasPrevious: links?.previous != undefined
    }
}
