import {combineNames, ShowDescription} from "../../../../logic/objects";
import React, {useContext} from "react";
import {Link, NavLink} from "react-router-dom";
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";

/**
 * The properties for the {@link ShowCard}.
 */
interface ShowCardProps {

    /**
     * The show being displayed.
     */
    show: ShowDescription

    /**
     * Deletes the local copy of the current show.
     */
    deleteLocalShow: () => void
}

/**
 * A component to display a {@link Show} that may be edited on a separate page.
 */
export const ShowCard = ({show, deleteLocalShow}: ShowCardProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)
    
    const deleteShow = (): void => {
        requestHandler.request(`/api/show/${show.id}`, {method: 'DELETE'})
            .then(res => {
                if (res.status == 200) {
                    deleteLocalShow()
                    toastHandler.addToast(new ToastInfo(`Deleted ${show.name}`, `Successfully deleted ${show.name}.`))
                }
            })
    }
    
    return (
        <div className="ShowCard card col-12" key={show.id}>
            <div className="card-body">
                <h5 className="card-title">{show.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{combineNames(show.owners)}</h6>
                
                <div className="mb-2 col-6">
                    <img src={show.image} alt={show.name} className="rounded w-100"/>
                </div>

                <Link to={`/admin/shows/${show.id}`} className="btn btn-primary">Edit</Link>
                <button className="btn btn-danger ms-2" onClick={deleteShow}>Delete</button>
            </div>
        </div>
    )
}