import React from 'react';
import './Users.scss';
import {ElevatedUser} from "../../../logic/objects";
import {InviteModal} from "./invite_modal/InviteModal";
import {UserCard} from "./user_card/UserCard";
import {AddModalOptions, DeleteItem, Lister, ModifyItem} from "../../portable/lister/Lister";
import usePaginatedFetcher from "../../../logic/usePaginatedFetcher";

/**
 * A list of all users, to view/edit them.
 */
export const Users = () => {

    const paginatedOptions = usePaginatedFetcher<ElevatedUser>('/api/user/list?count=20&page=0')

    const addModalOptions: AddModalOptions<ElevatedUser> = {
        buttonText: 'Invite User',
        modal: (visibleState, modifyItem) => <InviteModal showState={visibleState}/>
    }

    const displayItem = (item: ElevatedUser, modifyItem: ModifyItem<ElevatedUser>, deleteItem: DeleteItem): JSX.Element => (
        <UserCard key={item.id} user={item} updateLocalUser={modifyItem} deleteLocalUser={user => deleteItem(user.id)}/>
    )

    return (
        <div className="Users">
            <h3 className="text-orange mb-3">Manage Users</h3>

            <Lister displayOptions={paginatedOptions} displayItem={displayItem} addModalOptions={addModalOptions}/>
        </div>
    )
}
