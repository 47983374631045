import React, {useContext, useEffect, useState} from "react";
import {Navigate, Routes} from "react-router-dom";
import {Route} from "react-router";
import {Home} from "./body/Home";
import NotFound from "./error_pages/not_found/NotFound";
import {About} from "./about/About";
import {Schedule} from "./schedule/Schedule";
import {ShowPage} from "./show/Show";
import {Login} from "./login/Login";
import {Header} from "./header/Header";
import {Footer} from "./footer/Footer";
import {Shows} from "./shows/Shows";
import {Songs} from "./songs/Songs";
import {Contact} from "./contact/Contact";
import {StreamingBar} from "./streaming_bar/StreamingBar";
import TrackMultiReceiverContext, {TrackMultiReceiver} from "../contexts/TrackMultiReceiver";
import {RequestHandlerContext} from "../contexts/RequestHandler";
import {Register} from "./register/Register";
import {Role} from "../logic/self";
import Unauthorized from "./error_pages/unauthorized/Unauthorized";
import './App.scss';
import {ForgotPassword} from "./forgot_password/ForgotPassword";
import {PasswordReset} from "./password_reset/PasswordReset";
import {Admin} from "./admin_panel/AdminPanel";
import {ScheduleEdit} from "./admin_panel/schedule/ScheduleEdit";
import {TopSongsEdit} from "./admin_panel/top_songs/TopSongsEdit";
import {StaffManage} from "./admin_panel/staff_manage/StaffManage";
import {Users} from "./admin_panel/users/Users";
import {Files} from "./admin_panel/files/Files";
import {Upload} from "./admin_panel/files/upload/Upload";
import {DJPanel} from "./dj_panel/DJPanel";
import {WeeklyChart} from "./dj_panel/weekly_chart/WeeklyChart";
import {Listeners} from "./dj_panel/listener/Listeners";
import {Bible} from "./dj_panel/bible/Bible";
import {Support} from "./dj_panel/support/Support";
import {AccountPanel} from "./account_panel/AccountPanel";
import {Setup2FA} from "./account_panel/setup_2fa/Setup2FA";
import {SystemPost} from "./admin_panel/system_post/SystemPost";
import {loadCaptchaScript} from "../logic/recaptcha";
import {MusicRequests} from "./admin_panel/music_requests/MusicRequests";
import {ManageShows} from "./admin_panel/manage_shows/ManageShows";
import {EditShow} from "./admin_panel/manage_shows/edit_show/EditShow";
import {AskMunsonPage} from "./askmunson/AskMunsonPage";

export const LOGGER_REQUEST_URL = process.env.REACT_APP_LOGGER_API_URL ?? ''
export const WEBSOCKET_URL = process.env.REACT_APP_LOGGER_WEBSOCKET_URL ?? ''
export const TRACKS_PER_PAGE = parseInt(process.env.REACT_APP_TRACKS_PER_PAGE ?? '20')

/**
 * The top-level component of the application.
 * Paths under /admin and /dj are restricted, and require authentication before access.
 */
export const App = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The single receiver instance to get a socket of incoming played songs, and distribute it to any listening
     * component.
     */
    const [trackMultiReceiver] = useState<TrackMultiReceiver>(new TrackMultiReceiver())

    /**
     * Guards an element based on the role the currently authed user has. If the user isn't logged in yet, they are
     * given the login page. If they are logged in but don't have at least the given role, they are given a 403.
     *
     * @param element     The page component to protect
     * @param minimumRole The minimum role the user must have
     */
    const guarded = (element: JSX.Element, minimumRole?: Role | undefined): JSX.Element => {
        if (!requestHandler.loggedIn) {
            return <Login/>
        }

        if (minimumRole != undefined && !(requestHandler.self?.isAtLeast(minimumRole) ?? false)) {
            return <Unauthorized/>
        }

        return element
    }

    /**
     * Connects to the backend track receiver websocket on the music logger.
     */
    useEffect(() => {
        trackMultiReceiver.trackReceiver.connectWebsocket().then(success => {
            if (success) {
                // Getting the initial track
                trackMultiReceiver.trackReceiver.requestCurrentTrack()
            }
        })

        loadCaptchaScript()
    }, [])

    return (
        <TrackMultiReceiverContext.Provider value={trackMultiReceiver}>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}>
                    <Route index element={<Home/>}/>
                </Route>
                <Route path="about" element={<About/>}/>
                <Route path="schedule" element={<Schedule/>}/>
                <Route path="shows" element={<Shows/>}/>
                <Route path="songs" element={<Songs/>}/>
                <Route path="contact" element={<Contact/>}/>
                <Route path="show/:id" element={<ShowPage/>}/>
                <Route path="register" element={<Register/>}/>
                <Route path="login" element={guarded(<Navigate to="/"/>)}/>
                <Route path="forgot_password" element={<ForgotPassword/>}/>
                <Route path="reset_password" element={<PasswordReset/>}/>
                <Route path="account">
                    <Route index element={guarded(<AccountPanel/>)}/>
                    <Route path="setup_2fa" element={guarded(<Setup2FA/>)}/>
                </Route>
                <Route path="admin">
                    <Route index element={guarded(<Admin/>, Role.staff)}/>,
                    <Route path="schedule" element={guarded(<ScheduleEdit/>, Role.staff)}/>,
                    <Route path="top_songs" element={guarded(<TopSongsEdit/>, Role.staff)}/>,
                    <Route path="staff" element={guarded(<StaffManage/>, Role.staff)}/>,
                    <Route path="users" element={guarded(<Users/>, Role.staff)}/>
                    <Route path="files">
                        <Route index element={guarded(<Files/>, Role.staff)}/>
                        <Route path="edit/:id" element={guarded(<Upload/>, Role.staff)}/>
                        <Route path="upload" element={guarded(<Upload/>, Role.staff)}/>
                    </Route>
                    <Route path="system_post" element={guarded(<SystemPost/>, Role.staff)}/>
                    <Route path="music_requests" element={guarded(<MusicRequests/>, Role.staff)}/>
                    <Route path="shows">
                        <Route index element={guarded(<ManageShows/>)}/>
                        <Route path=":id" element={guarded(<EditShow/>, Role.staff)}/>
                    </Route>
                </Route>
                <Route path="dj">
                    <Route index element={guarded(<DJPanel/>, Role.dj)}/>,
                    <Route path="top" element={guarded(<WeeklyChart/>, Role.dj)}/>
                    <Route path="broadcastListeners/:studio" element={guarded(<Listeners/>, Role.dj)}/>
                    <Route path="bible" element={guarded(<Bible/>, Role.dj)}/>
                    <Route path="support" element={guarded(<Support/>, Role.dj)}/>
                </Route>
                {/*<Route path="askmunson" element={<AskMunsonPage/>}/>*/}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
            <StreamingBar/>
        </TrackMultiReceiverContext.Provider>
    );
}
