import React, {useContext, useEffect, useState} from 'react';
import './StaffManage.scss';
import RequestHandlerContext from "../../../contexts/RequestHandler";
import {Department, Departments, Staff, StaffCreate, StaffOrder} from "../../../logic/objects";
import {capitalizeFirst} from "../../../logic/utility";
import {UploadImage} from "../../portable/upload_image/UploadImage";
import {ToastHandlerContext} from "../../../contexts/ToastHandler";
import {AddStaffModal} from "./add_staff/AddStaff";
import ImageService from "../../../logic/image_service";
import {usePopper} from "react-popper";
import {
    AddModalOptions,
    DeleteItem,
    HandleEvent,
    Lister,
    ModifyItem,
    SingleListOptions
} from "../../portable/lister/Lister";
import {classes} from "../../../logic/react_utility";

/**
 * A page to manage {@link Staff} displayed on the contacts page.
 */
export const StaffManage = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * Fetches all staff members.
     */
    const fetchStaff = (): Promise<Staff[]> => {
        return requestHandler.request('/api/staff/list')
            .then(async res => {
                let json = await res.json()
                let staff = json as Staff[]
                return staff.sort((a, b) => a.order - b.order)
            })
    }

    /**
     * Updates the staff member's orders to the backend.
     *
     * @param staff All staff members
     */
    const updateAllStaffOrders = (staff: Staff[]): Promise<void> => {
        return requestHandler.request('/api/staff/order', {
            method: 'POST', body: JSON.stringify(staff.map(person => {
                return {
                    id: person.id,
                    order: person.order
                } as StaffOrder
            }))
        }).then()
    }

    /**
     * Changes the index of a given staff member, changing all other staff members' order numbers accordingly, updating
     * the backend via {@link updateAllStaffOrders}
     *
     * @param staff            The staff member to change their order
     * @param newIndex         The new index of the staff member in the array
     * @param currentStaffList The current list being displayed
     */
    const changeStaffOrder = (staff: Staff, newIndex: number, currentStaffList: Staff[]): Staff[] => {
        let oldIndex = currentStaffList.indexOf(staff)
        if (oldIndex == -1) {
            return currentStaffList
        }

        currentStaffList.splice(oldIndex, 1)
        currentStaffList.splice(newIndex, 0, staff)

        currentStaffList.forEach((mutatingStaff, i) => mutatingStaff.order = i)
        updateAllStaffOrders(currentStaffList)
        return currentStaffList
    }

    const singleListOptions: SingleListOptions<Staff> = {
        fetchItems: fetchStaff,
        changeOrder: changeStaffOrder
    }

    const addModalOptions: AddModalOptions<Staff> = {
        buttonText: 'Add Staff',
        modal: (visibleState, modifyItem) => <AddStaffModal showState={visibleState} addStaff={modifyItem}/>
    }

    const displayItem = (item: Staff, modifyItem: ModifyItem<Staff>, deleteItem: DeleteItem, onHandleEvent: HandleEvent): JSX.Element => (
        <StaffDisplay key={item.id} staff={item} modifyLocalStaff={modifyItem} deleteLocalStaff={deleteItem} refreshStaff={fetchStaff} onHandleEvent={onHandleEvent}/>
    )

    return (
        <div className="StaffList">
            <h3 className="text-orange mb-3">Manage Staff</h3>

            <Lister displayOptions={singleListOptions} displayItem={displayItem} addModalOptions={addModalOptions}/>
        </div>
    )
}

/**
 * The properties for {@link StaffDisplay}.
 */
interface StaffDisplayProps {

    /**
     * The staff to display.
     */
    staff: Staff

    /**
     * Modifies the cached version of a {@link Staff}, without sending any external requests to the backend. This
     * should be invoked when an image or other property is updated for a staff member, after an API request has
     * successfully been made.
     *
     * @param modifyingFunction A function to mutate the current staff member
     */
    modifyLocalStaff: ModifyItem<Staff>

    /**
     * Deletes the local copy of a {@link Staff} by their ID.
     */
    deleteLocalStaff: DeleteItem

    /**
     * Refreshes the staff list
     */
    refreshStaff: () => void

    onHandleEvent: HandleEvent
}

/**
 * A component to display a single {@link Staff} that can be managed.
 */
const StaffDisplay = ({staff, modifyLocalStaff, deleteLocalStaff, refreshStaff, onHandleEvent}: StaffDisplayProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * The name of the staff.
     */
    const [name, setName] = useState<string>('')

    /**
     * The department of the staff.
     */
    const [department, setDepartment] = useState<Department>('general')

    /**
     * The staff's position.
     */
    const [position, setPosition] = useState<string>('')

    /**
     * The email of the staff.
     */
    const [email, setEmail] = useState<string>('')

    /**
     * If the name is being edited.
     */
    const [editingName, setEditingName] = useState<boolean>(false)

    /**
     * If the department is being edited.
     */
    const [editingDepartment, setEditingDepartment] = useState<boolean>(false)

    /**
     * If the position is being edited.
     */
    const [editingPosition, setEditingPosition] = useState<boolean>(false)

    /**
     * If the email is being edited.
     */
    const [editingEmail, setEditingEmail] = useState<boolean>(false)

    /**
     * The element being used as a root for the popup menu.
     */
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    /**
     * The element being popped up as a menu of actions.
     */
    const [popupElement, setPopupElement] = useState<HTMLDivElement | null>(null);

    /**
     * If the popup menu is visible.
     */
    const [visible, setVisible] = useState<boolean>(false);

    /**
     * The created popper fields.
     *
     * @param styles     The styles to be applied to the popup element
     * @param attributes The attributes to be applied to the popup element
     * @param update     A function to update the popup element
     */
    const {styles, attributes, update} = usePopper(referenceElement, popupElement, {
        placement: 'bottom-start',
        modifiers: [
            {name: 'preventOverflow', enabled: true}
        ]
    });

    /**
     * Invokes a {@link update} when visibility is changed. This is so the popup element has a valid initial state when
     * becoming visible.
     */
    useEffect(() => {
        update?.call(this)
    }, [visible])

    /**
     * Invokes the {@link modifyLocalStaff} callback to modify the staff's primary or secondary image.
     *
     * @param imagePath The new image path
     * @param primary   If the primary image should be set. `false` for secondary.
     */
    const updateLocalImage = (imagePath: string, primary: boolean): void => {
        imagePath += '?' + (Date.now())

        if (primary) {
            staff.image = imagePath
        } else {
            staff.secondaryImage = imagePath
        }

        modifyLocalStaff(staff)
    }

    /**
     * Upload an image {@link Blob} to the backend and applies it to the staff member, invoking
     * {@link updateLocalImage} after a successful upload.
     *
     * @param image The processed image blob
     * @param primary If the image to be uploaded is the primary image (`true`), or hover/secondary image (`false`)
     */
    const uploadImage = (image: Blob, primary: boolean): void => {
        ImageService.uploadStaffImage(requestHandler, staff, primary, image, toastHandler, updateLocalImage)
    }

    /**
     * Makes a request to modify the {@link Staff} with new properties. Upon a successful change,
     * {@link modifyLocalStaff} is invoked with `changeCallback`.
     *
     * @param modifiedInfo Any fields to set for the existing {@link Staff}. Any unset fields will be disregarded
     */
    const modifyStaff = (modifiedInfo: StaffCreate): Promise<void> => {
        return requestHandler.request(`/api/staff/${staff.id}`, {method: 'POST', body: JSON.stringify(modifiedInfo)})
            .then(async res => {
                if (res.status == 200) {
                    let json = await res.json()
                    modifyLocalStaff(json as Staff)
                }
            })
    }

    /**
     * Deletes the {@link Staff}.
     */
    const deleteStaff = (): void => {
        requestHandler.request(`/api/staff/${staff.id}`, {method: 'DELETE'})
            .then(res => {
                if (res.status == 200) {
                    deleteLocalStaff(staff.id)
                }
            })
    }

    /**
     * Sets the {@link name} to the staff's current name, and sets {@link editingName} to `true` so the input box is
     * shown.
     */
    const beginEditingName = (): void => {
        setName(staff.name)
        setEditingName(true)
    }

    /**
     * Hides the input box via {@link editingName} and invokes {@link modifyStaff} with updated information.
     */
    const onSaveName = (): void => {
        setEditingName(false)
        modifyStaff({
            name: name
        })
    }

    /**
     * Sets the {@link department} to the staff's current department, and sets {@link editingDepartment} to `true` so
     * the input box is
     * shown.
     */
    const beginEditingDepartment = (): void => {
        setDepartment(staff.department)
        setEditingDepartment(true)
    }

    /**
     * Hides the input box via {@link editingDepartment} and invokes {@link modifyStaff} with updated information.
     */
    const onSaveDepartment = (): void => {
        setEditingDepartment(false)
        modifyStaff({
            department: department
        })
    }

    /**
     * Toggles the eboard status of the user.
     */
    const onToggleEboard = (): void => {
        modifyStaff({
            eboard: !staff.eboard
        })
    }

    /**
     * Sets the {@link position} to the staff's current position, and sets {@link editingPosition} to `true` so the
     * input box is shown.
     */
    const beginEditingPosition = (): void => {
        setPosition(capitalizeFirst(staff.position))
        setEditingPosition(true)
    }

    /**
     * Hides the input box via {@link editingPosition} and invokes {@link modifyStaff} with updated information.
     */
    const onSavePosition = (): void => {
        setEditingPosition(false)
        modifyStaff({
            position: position
        })
    }

    /**
     * Sets the {@link email} to the staff's current position, and sets {@link editingEmail} to `true` so the input box is
     * shown.
     */
    const beginEditingEmail = (): void => {
        setEmail(staff.email)
        setEditingEmail(true)
    }

    /**
     * Hides the input box via {@link editingEmail} and invokes {@link modifyStaff} with updated information.
     */
    const onSaveEmail = (): void => {
        setEditingEmail(false)
        modifyStaff({
            email: email
        })
    }

    /**
     * Performs an action in the form of a callback on a key event, generally an {@link onKeyDown}.
     *
     * @param e        The keyboard event
     * @param onSave   The callback to invoke if the key is Enter
     * @param onCancel The callback to invoke if the key is Escape
     */
    const actionOnKey = (e: React.KeyboardEvent<HTMLInputElement>, onSave: () => void, onCancel: () => void) => {
        if (e.key == 'Enter') {
            onSave()
        }

        if (e.key == 'Escape') {
            onCancel()
        }
    }

    return (
        <div className="staff-display card" key={staff.id}>
            <div key={staff.id + '_handle'} className="position-absolute menu-wrapper"
                 onMouseDown={e => onHandleEvent('mousedown', e)}>
                <div className="p-2" ref={setReferenceElement} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
                        <span className="more-dots material-symbols-outlined p-1">
                            more_vert
                        </span>

                    {visible &&
                        <div ref={setPopupElement} className="popup btn-group-vertical bg-white" role="group" aria-label="Vertical button group" style={styles.popper} {...attributes.popper}>
                            <button type="button" className="btn btn-outline-danger" onClick={deleteStaff}>Delete</button>
                        </div>}
                </div>
            </div>

            <div className="card-body">
                <h5 className="card-title editable d-flex align-items-center mb-3">
                    {!editingName && <>
                        <span onClick={beginEditingName}>{staff.name}</span>
                        <span className="edit material-symbols-outlined ms-3" onClick={beginEditingName}>edit</span>
                    </>}

                    {editingName && <>
                        <input ref={ref => ref?.focus()} className="form-control" value={name} onChange={e => setName(e.target.value)} onKeyDown={e => actionOnKey(e, onSaveName, () => setEditingName(false))}/>
                        <span className="cancel material-symbols-outlined ms-2" onClick={() => setEditingName(false)}>close</span>
                        <span className="done material-symbols-outlined ms-2" onClick={onSaveName}>done</span>
                    </>}
                </h5>

                <div className="row">
                    <div className="col-6">
                        <UploadImage title="Adjust Profile Picture" imagePreview={staff.image} imageUploaded={(uploaded, preview) => uploadImage(uploaded, true)}/>
                        <p className="text-center mt-1">Primary Image</p>
                    </div>
                    <div className="col-6">
                        <UploadImage title="Adjust Profile Picture" imagePreview={staff.secondaryImage} imageUploaded={(uploaded, preview) => uploadImage(uploaded, false)}/>
                        <p className="text-center mt-1">Hover Image</p>
                    </div>
                </div>

                <p className="card-text mt-3 editable d-flex align-items-center">

                    {!editingDepartment && <>
                        <span className="material-symbols-outlined inline-icon me-2">work</span>
                        <span onClick={beginEditingDepartment}>{capitalizeFirst(staff.department ?? '')}</span>
                        <span className="edit material-symbols-outlined ms-auto" onClick={beginEditingDepartment}>edit</span>
                    </>}

                    {editingDepartment && <>
                        <select ref={ref => ref?.focus()} className="form-select" aria-label="Default select example" value={department} onChange={e => setDepartment(e.target.value as Department)}>
                            {Departments.map(department =>
                                <option key={department} value={department}>{capitalizeFirst(department)}</option>)}
                        </select>
                        <span className="cancel material-symbols-outlined ms-2" onClick={() => setEditingDepartment(false)}>close</span>
                        <span className="done material-symbols-outlined ms-2" onClick={onSaveDepartment}>done</span>
                    </>}
                </p>

                <p className="card-text mt-3 editable d-flex align-items-center">

                    {!editingPosition && <>
                        <span className="material-symbols-outlined inline-icon me-2">group</span>
                        <span className={classes("eboard-status material-symbols-outlined me-2", ['eboard-status-show', staff.eboard])} onClick={onToggleEboard}>shield_person</span>
                        <span onClick={beginEditingPosition}>{capitalizeFirst(staff.position ?? '')}</span>
                        <span className="edit material-symbols-outlined ms-auto" onClick={beginEditingPosition}>edit</span>
                    </>}

                    {editingPosition && <>
                        <input ref={ref => ref?.focus()} className="form-control" value={position} onChange={e => setPosition(e.target.value)} onKeyDown={e => actionOnKey(e, onSavePosition, () => setEditingPosition(false))}/>
                        <span className="cancel material-symbols-outlined ms-2" onClick={() => setEditingPosition(false)}>close</span>
                        <span className="done material-symbols-outlined ms-2" onClick={onSavePosition}>done</span>
                    </>}
                </p>

                <p className="card-text mt-3 editable d-flex align-items-center">
                    {!editingEmail && <>
                        <span className="material-symbols-outlined inline-icon me-2">mail</span>
                        <span onClick={beginEditingEmail}>{staff.email}</span>
                        <span className="edit material-symbols-outlined ms-auto" onClick={beginEditingEmail}>edit</span>
                    </>}

                    {editingEmail && <>
                        <input ref={ref => ref?.focus()} className="form-control" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={e => actionOnKey(e, onSaveEmail, () => setEditingEmail(false))}/>
                        <span className="cancel material-symbols-outlined ms-2" onClick={() => setEditingEmail(false)}>close</span>
                        <span className="done material-symbols-outlined ms-2" onClick={onSaveEmail}>done</span>
                    </>}
                </p>
            </div>
        </div>
    )
}
