import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button} from "../../button/Button";
import {Result, statusToResult} from "../../../logic/requests";
import useForm from "../../../logic/useForm";
import RequestHandlerContext from "../../../contexts/RequestHandler";
import {TOPTInitialInfo} from "../../../logic/objects";

/**
 * The inputs that are mapped to the form by an input's name.
 */
type FormInputs = {
    /**
     * The initial 2FA code to validate.
     */
    totpCode: string
}

/**
 * A page to set the currently logged-in user's 2FA. If 2FA is already set, this will reset it.
 */
export const Setup2FA = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The status of the result.
     */
    const [result, setResult] = useState<Result>(Result.Waiting)

    /**
     * TOTP Initial info, containing the secret to put in a 2FA application.
     */
    const [initialInfo, setInitialInfo] = useState<TOPTInitialInfo | undefined>()

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<FormInputs>()

    /**
     * Gets the initial QR code/secret to reset 2FA.
     */
    useEffect(() => {
        requestHandler.request('/api/auth/mfa/reset/begin')
            .then(async res => {
                let json = await res.json()
                setInitialInfo(json as TOPTInitialInfo)
            })
    }, [])

    /**
     * Completes the registration with the form data.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        requestHandler.request('/api/auth/mfa/reset/complete', {
            method: 'POST', body: JSON.stringify({
                totpCode: inputs.totpCode
            })
        }).then(res => setResult(statusToResult(res.status))) // TODO: Log out user to reset {@link Self}?
    }

    return (
        <div className="Reset2FA d-flex flex-row justify-content-center">
            <form className="col-8 col-md-4 col-xxl-3 my-5" onSubmit={e => handleSubmit(e, processSubmit)}>
                {initialInfo != undefined && <Fragment>
                    <div className="mb-4 text-center d-flex flex-column">
                        <img src={initialInfo.qrCode} alt="2FA QR Code" className="qr"/>
                        <p className="mb-0">{initialInfo.secret}</p>
                    </div>

                    <div className="mb-4">
                        <input type="text" name="totpCode" inputMode="numeric" pattern="[0-9]*" maxLength={6} autoComplete="one-time-code" placeholder="6 Digit Code" className="form-control underline-input"/>
                    </div>
                </Fragment>}

                <Button submit={true} className="mx-auto">Reset</Button>

                {result == Result.Success && <p className="text-success mt-3">
                    Successfully set 2FA
                </p>}

                {(result == Result.Unexpected || result == Result.InternalError) && <p className="text-danger mt-3">
                    An unexpected error occurred while setting 2FA
                </p>}

                {result == Result.Unauthorized && <p className="text-danger mt-3">
                    Invalid 2FA Code
                </p>}
            </form>
        </div>
    )
}
