import React, {Fragment, ReactElement, useContext, useState} from 'react';
import './Show.scss';
import {useParams} from "react-router";
import DOMPurify from 'dompurify';
import {marked} from 'marked';
import useForm from "../../logic/useForm";
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import loadable from "@loadable/component";
import EasyMDE from "easymde";

// TODO: loadable is improperly being used! It returns a component, NOT the library! Fix before using
// @ts-ignore
// const EasyMDE = loadable.lib(() => import('easymde'))

// TODO: This hasn't been implemented yet/is legacy!

/**
 * A show to be displayed in the "Shows" dropdown menu.
 */
export class Show {

    /**
     * The display name of the show.
     */
    name: string

    /**
     * The unique ID of the show. This may be change when routes are set up.
     * TODO: Routes
     */
    id: string

    /**
     * The owners of this show
     */
    owners: string[]

    /**
     * The description/body of the show
     */
    description: string

    constructor(name: string, id: string, owners: string[], description: string) {
        this.name = name
        this.id = id
        this.owners = owners
        this.description = description
    }
}

export class Post {
    /**
     * The UUID of the post
     */
    uuid: string

    /**
     * The title of the post
     */
    name: string

    /**
     * The name of the author
     */
    creator: string

    /**
     * The actual markdown content of the posr
     */
    content: string

    /**
     * If the post is considered a draft, and therefore, private to normal users
     */
    draft: boolean

    /**
     * If the post is archived
     */
    archived: boolean

    /**
     * The date this post was uploaded
     */
    dateUploaded: string

    /**
     * The markdown editor object. If undefined, the post is not being edited.
     */
    editor: EasyMDE | undefined

    /**
     * Returns if the post is being edited. This simple does a check if the editor is not undefined, meaning it is safe
     * to assume an editor is present if this returns true.
     */
    get editing(): boolean {
        return this.editor != undefined
    }

    /**
     * Set before {@link editing} is actually true, this tells the post to begin the creation of the markdown editor.
     * Once the textarea is loaded in the editing display, the markdown editor is rendered and {@link editing} is true.
     */
    preEdit: boolean = false

    constructor(uuid: string, name: string, creator: string, content: string, draft: boolean, archived: boolean, dateUploaded: string) {
        this.uuid = uuid;
        this.name = name;
        this.creator = creator;
        this.content = content;
        this.draft = draft;
        this.archived = archived;
        this.dateUploaded = dateUploaded;
    }
}

export const shows: Show[] = [new Show("one", "one-id", ["uuid1"], "This is some text **body** of the show!"),
    new Show("two", "two-id", ["uuid1"], "This is some text **body** of the show!"),
    new Show("three", "three-id", ["uuid1"], "This is some text **body** of the show!")];

export const postsTemp: Post[] = [new Post('uuid1', 'My First Post', 'Adam Yarris', 'This _is_ a **post**. This could contain images and stuff ![alt stuff](https://witr.rit.edu/img/header/witr_logo.png) Text below', false, false, '2022-1-12'),
    new Post('uuid2', 'Something Else', 'Ethan', 'Some stuff that will be **edited**.', false, false, '2022-1-13'),
    new Post('uuid3', 'A Draft', 'Adam Yarris', 'This is a draft and can be _edited_.', true, false, '2022-1-14')]

/**
 * An individual show. This will show blog posts, maybe a history of recordings, and other info.
 */
export const ShowPage = () => {
    const {id} = useParams<'id'>();
    const [editing, setEditing] = useState(false);
    const [posts, setPosts] = useState<Post[]>(postsTemp)
    const requestHandler = useContext(RequestHandlerContext)

    type FormInputs = {
        title: string,
        draft: boolean
    }

    const {handleSubmit} = useForm<FormInputs>()

    // TODO: Integrate with the API
    const show = shows.find(v => v.id == id)

    if (show === undefined) {
        return <p>Bad show</p>
    }

    /**
     * Update any changes that occurred in any {@link Post}
     */
    function updatePosts() {
        setPosts([...posts])
    }

    /**
     * Sets {@link Post#preEdit} to true, so the editing view can start to be rendered.
     * @param e The mouse event to prevent default
     * @param post The post being edited
     */
    function clickEdit(e: React.MouseEvent, post: Post) {
        e.preventDefault();
        window.history.pushState(null, '', '#' + post.uuid);
        post.preEdit = true
        updatePosts()
    }

    /**
     * Invoked when the textarea is rendered. This creates the markdown editor and sets the {@link Post} as editing.
     * @param post The {@link Post} being edited
     * @param textArea The textarea the editor uses
     */
    function beginEditing(post: Post, textArea: HTMLTextAreaElement | null) {
        if (textArea != null && post.editor == undefined) {
            // @ts-ignore
            post.editor = new EasyMDE({
                element: textArea,
                initialValue: post.content,
                spellChecker: false,
                autosave: {
                    uniqueId: post.uuid,
                    enabled: true
                }
            })

            updatePosts()
        }
    }

    /**
     * Stops any editing and reverts the view back to normal viewing mode.
     * @param post The post being switched back
     */
    function clickStopEdit(post: Post) {
        post.editor?.toTextArea()
        post.editor = undefined
        post.preEdit = false
        updatePosts()
    }

    /**
     * Processes a submission when the "Update" button is pressed, giving the server the new content.
     * @param post The post to submit
     * @param inputs The {@link FormInputs} used
     */
    const processSubmit = async (post: Post, inputs: FormInputs) => {
        console.log('Sending update request'); // TODO: Update request and (properly?) update post object
        post.content = post.editor!.value()
        post.draft = inputs.draft
        post.name = inputs.title
        clickStopEdit(post)
    }

    /**
     * Renders the {@link Post} in normal viewing mode.
     * @param post The {@link Post} to display
     * @returns The display fragment
     */
    function renderDisplay(post: Post): ReactElement {
        return <Fragment>
            <div className="title-line">
                <h3 className="title">{post.name}</h3>

                {post.draft && <span className="draft">(Draft)</span>}

                <span className="edit" onClick={(e) => clickEdit(e, post)}>Edit</span>
            </div>

            <div className="creation-info">
                <span>{post.creator}</span>
                <span>{post.dateUploaded}</span>
            </div>

            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(post.content))}}
                 className="markdown content"/>
        </Fragment>
    }

    /**
     * Renders the {@link Post} in editing mode.
     * @param post The {@link Post} being rendered
     * @returns The editing display form
     */
    function renderEdit(post: Post): ReactElement {
        return <form onSubmit={e => handleSubmit(e, inputs => processSubmit(post, inputs))}>
            <div className="title-line">
                <input type="text" name="title" defaultValue={post.name}/>

                <span className="edit persistent" onClick={(e) => clickStopEdit(post)}>Stop
                    Editing</span>
            </div>

            <div className="creation-info">
                <span>{post.creator}</span>
                <span>{post.dateUploaded}</span>
            </div>

            <label>
                <span>Draft</span>
                <input type="checkbox" name="draft" defaultChecked={post.draft}/>
            </label>

            <textarea ref={ref => beginEditing(post, ref)} className="editor-textarea"/>

            <button className="update" type="submit">Update</button>
        </form>
    }

    // TODO: This assumed you are the owner of the show. Self info has not yet been implemented yet
    return (
        <div className="Show">
            <h2>Show {id} {requestHandler.loggedIn &&
                <span className="show-edit" onClick={() => setEditing(!editing)}>(edit)</span>}</h2>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(show.description))}} className="markdown"/>
            {editing && <span>Editing now!</span>}
            <div className="blog-posts">
                {posts.map((post, i) =>
                    <div key={post.uuid} className="post" id={post.uuid}>
                        {post.preEdit ? renderEdit(post) : renderDisplay(post)}
                    </div>
                )}
            </div>
        </div>
    );
}
