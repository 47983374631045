import './ReCaptchaNotice.scss'
import {classes} from "../../../logic/react_utility";

/**
 * Properties for the notice.
 */
interface ReCaptchaNoticeProps {

    /**
     * If the captcha is visible.
     */
    visible: boolean

    /**
     * Any additional classes to add to the displaying div.
     */
    className?: string
}

/**
 * A component to display Google reCAPTCHA's legal notice, as the badge is hidden in the website.
 * @see {@link https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed}
 */
export const ReCaptchaNotice = ({visible, className}: ReCaptchaNoticeProps): JSX.Element => {
    return (
        <div className={classes('ReCaptchaNotice text-muted', className ?? '', ['visible', visible])}>
            This site is protected by reCAPTCHA and the
            Google <a href="https://policies.google.com/privacy" target="_blank" className="text-decoration-none">Privacy
            Policy</a> and <a href="https://policies.google.com/terms" target="_blank" className="text-decoration-none">Terms of
            Service</a> apply.
        </div>
    )
}
