import React from 'react'
import './Files.scss'
import prettyBytes from "pretty-bytes";
import {Link} from "react-router-dom";

export class File {
    id: string
    name: string
    url: string
    size: number
    restricted: boolean

    constructor(id: string, name: string, url: string, size: number, restricted: boolean) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.size = size;
        this.restricted = restricted;
    }
}

export const files: File[] = [new File("uuid1", "Feet.zip", "https://witr.rit.edu/files/Feet.zip", 1560000000, true),
    new File("uuid2", "fcc.pdf", "https://witr.rit.edu/files/fcc.pdf", 15300000, false)]

/**
 * A list displaying static files of the websites, for things like FCC reports.
 * Currently unused.
 */
export const Files = () => {
    return (
        <div className="Files">
            <h2>File Management</h2>
            <p>Below are all files uploaded and used throughout the station. Restricted files are only available for logged-in users.</p>
            <Link className="upload" to="/admin/files/upload">Upload File</Link>
            <div className="files">
                {files.map(file => <div className="file">
                    <span>{file.name}</span>
                    <span>{prettyBytes(file.size)}</span>
                    <a target="_blank" rel="noreferrer" href={file.url}>{file.url}</a>
                    {file.restricted && <span className="restricted">Restricted</span>}
                    <div className="buttons">
                        <Link className="modify" to={`/admin/files/edit/${file.id}`}>Modify</Link>
                        <button className="delete">Delete</button>
                    </div>
                </div>)}
            </div>
        </div>
    )
}
