import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";
import Obfuscate from 'react-obfuscate';

/**
 * The static footer component of the website. This will show things like RIT, WITR, and FCC info.
 */
export const Footer = () => {
    return (
        <div className="Footer content-wrapper">
            <div className="content">
                <div className="column">
                    <Link to="/about">About</Link>
                    <Link to="/schedule">Schedule</Link>
                    <Link to="/shows">Shows</Link>
                    <Link to="/songs">Songs</Link>
                    <Link to="/contact">Contact</Link>
                    <a href="https://www.rit.edu/giving/makeagift/">Donate</a>
                </div>
                <div className="column">
                    <span className="title">FCC Guidelines</span>
                    <a href="https://publicfiles.fcc.gov/fm-profile/witr">WITR Public File</a>
                </div>
                <div className="column">
                    <span className="title">Social Media</span>
                    <a href="https://www.instagram.com/witr897/">IG @witr897</a>
                    <a href="https://twitter.com/witr897">Twitter @witr897</a>
                    <a href="https://www.facebook.com/WITR897/">Facebook WITR Radio</a>
                </div>
                <div className="column">
                    <div className="column-row">
                        <span className="title">Contact Us</span>
                        <span>(585) 475-2000</span>
                        <Obfuscate email="radio@witr.rit.edu"/>
                    </div>

                    <div className="column-row mt-4">
                        <span className="title">Report an Issue</span>
                        <Obfuscate email="support@witr.rit.edu"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
