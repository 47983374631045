import React, {useEffect, useState} from "react";
import './UploadImage.scss';
import Croppie from "croppie";
import {classes, State} from "../../../logic/react_utility";
import {Modal} from "../modal/Modal";

type BlobWithPreviewConsumer = (image: Blob, preview: string) => void
type UploadedImageConsumer = (uploadedImage: UploadedImage) => void
type BlobConsumer = (image: Blob) => void

/**
 * A type to store the output of an image that was processed by Croppie.
 */
export type UploadedImage = {
    /**
     * The actual image blob to be uploaded
     */
    blob: Blob

    /**
     * The base64 string to preview the image
     */
    preview: string
}

/**
 * Properties for {@link UploadedImage}.
 */
interface UploadImageProps {

    /**
     * The title of the croppie modal, shown after selecting an image.
     */
    title: string

    /**
     * Additional classNames to add to the root
     */
    className?: string | undefined

    /**
     * The URL to the image being displayed. The owner of this component should update this when {@link imageUploaded}
     * is invoked. This does not have to be full resolution.
     */
    imagePreview: string | undefined

    /**
     * A callback invoked when a new image is uploaded.
     */
    imageUploaded: BlobWithPreviewConsumer | UploadedImageConsumer

    /**
     * Optional properties to pass to Croppie. Any unset properties will be filled with default values.
     */
    croppieOptions?: Croppie.CroppieOptions
}

/**
 * A component that displays a preview of an image (or a blank button that would be the same size as the image) that
 * allows you to change/upload the image. Upon selecting the image, an adjusting modal is shown to crop confirm the
 * image.
 */
export const UploadImage = ({title, className, imagePreview, imageUploaded, croppieOptions}: UploadImageProps) => {

    /**
     * The selected file {@link Blob} from an {@link HTMLInputElement}.
     */
    const fileBlobState = useState<Blob | undefined>()
    const [fileBlob, setFileBlob] = fileBlobState

    /**
     * A reference to the file input element, used to reset the selected file when an image is submitted.
     */
    const [fileInput, setFileInput] = useState<HTMLInputElement | null>()

    /**
     * Sets the {@link fileBlob} to the input file.
     *
     * @param event The file input change event
     */
    const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let input = event.target
        if (input.files != null && input.files[0] != undefined) {
            setFileBlob(input.files[0])
        }
    }

    /**
     * Returns an element with a plus on it, with no preview image.
     */
    const renderAdd = (): JSX.Element => {
        return (
            <button type="button" className="image-preview btn btn-outline-secondary">
                <input ref={setFileInput} className="image-upload" type="file" accept="image/*" onChange={onChangeImage}/>
                <span className="material-symbols-outlined inline-icon">add</span>
            </button>
        )
    }

    /**
     * Returns an element with the {@link imagePreview} as a background, and a hoverable "edit" icon.
     */
    const renderEdit = (): JSX.Element => {
        return (
            <div style={{backgroundImage: `url(${imagePreview})`}} className="image-preview">
                <div className="edit-image">
                    <input ref={setFileInput} className="image-upload" type="file" accept="image/*" onChange={onChangeImage}/>
                    <span className="material-symbols-outlined inline-icon">edit</span>
                </div>
            </div>
        )
    }

    /**
     * Clears the current `<input>` element used for selecting files. This is so the same file may be selected again
     * through the element, still causing an `onChange` event.
     */
    const clearFileInput = (): void => {
        if (fileInput != null) {
            fileInput.value = ''
        }
    }

    /**
     * Cleans up the UploadImage component and invokes the {@link imageUploaded} callback with the final processed
     * image.
     *
     * @param imageBlob     The {@link Blob} of the image
     * @param base64Preview The base64 preview of the image
     */
    const imageModalComplete = (imageBlob: Blob, base64Preview: string): void => {
        clearFileInput()
        
        if (imageUploaded.length == 1) { // UploadedImageConsumer
            let uploadedImageConsumer = imageUploaded as UploadedImageConsumer
            uploadedImageConsumer({blob: imageBlob, preview: base64Preview})
        } else { // BlobWithPreviewConsumer
            let blobWithPreviewConsumer = imageUploaded as BlobWithPreviewConsumer
            blobWithPreviewConsumer(imageBlob, base64Preview)
        }
    }
    
    return (
        <div className={classes('UploadImage', className)}>
            <UploadImageModal title={title} fileBlobState={fileBlobState} imageUploaded={imageModalComplete} uploadCancelled={clearFileInput} croppieOptions={croppieOptions}/>
            
            {imagePreview == undefined || imagePreview == '' ? renderAdd() : renderEdit()}
        </div>
    )
}

/**
 * Properties for {@link UploadImageModal}.
 */
interface UploadImageModalProps {

    /**
     * The title of the croppie modal, shown after selecting an image.
     */
    title: string

    /**
     * The file data being uploaded. This will be selected by an {@link HTMLInputElement}.
     */
    fileBlobState: State<Blob | undefined>

    /**
     * Invoked when an image is finalized by croppie. This should generally be a staff update request.
     *
     * @param image The cropped image content blob
     * @param preview A base64 preview of the image
     */
    imageUploaded: BlobWithPreviewConsumer | BlobConsumer

    /**
     * Invoked when "Cancel" is selected on the modal.
     */
    uploadCancelled?: () => void

    /**
     * Optional properties to pass to Croppie. Any unset properties will be filled with default values.
     */
    croppieOptions?: Croppie.CroppieOptions
}

/**
 * A popup modal to handle cropping and potentially various other processing after selecting a file but before
 * uploading to the backend.
 */
export const UploadImageModal = ({title, fileBlobState: [fileBlob, setFileBlob], imageUploaded, uploadCancelled, croppieOptions}: UploadImageModalProps) => {
    const [croppie, setCroppie] = useState<Croppie | undefined>()

    /**
     * The state if the model should be visible.
     */
    const showState = useState<boolean>(false)
    const [show, setShow] = showState

    /**
     * When the {@link fileBlob} is set, show the modal and invoke {@link initBlob}.
     */
    useEffect(() => {
        if (fileBlob != undefined) {
            setShow(true)
            initBlob()
        }
    }, [fileBlob])

    /**
     * Binds {@link Croppie} to the selected file, {@link fileBlob}.
     */
    const initBlob = (): void => {
        if (croppie == undefined || fileBlob == undefined) {
            return
        }

        let reader = new FileReader()
        reader.onload = (e) => {
            croppie?.bind({
                // @ts-ignore
                url: e.target?.result
            })
        }

        reader.readAsDataURL(fileBlob);
    }

    /**
     * Initializes the selected file using {@link initBlob} if {@link croppie} is set. This is to ensure it is
     * initialized if {@link croppie} was not set yet.
     */
    useEffect(() => {
        initBlob()
    }, [croppie])

    /**
     * Sets {@link croppie} if it hasn't been set before. This is invoked upon reference initialization of the canvas
     * container div.
     *
     * @param ref The wrapper element provided by a reference
     */
    const uploadDivInit = (ref: HTMLDivElement | null): void => {
        if (ref != null) {
            setCroppie(old => {
                if (old != undefined) {
                    return old
                }

                return new Croppie(ref, {
                    boundary: croppieOptions?.boundary ?? {
                        width: 300,
                        height: 300
                    },
                    customClass: croppieOptions?.customClass,
                    enableExif: croppieOptions?.enableExif ?? true,
                    enableOrientation: croppieOptions?.enableOrientation,
                    enableZoom: croppieOptions?.enableZoom ?? true,
                    enforceBoundary: croppieOptions?.enforceBoundary ?? true,
                    enableResize: croppieOptions?.enableResize ?? false,
                    mouseWheelZoom: croppieOptions?.mouseWheelZoom ?? 'ctrl',
                    showZoomer: croppieOptions?.showZoomer ?? true,
                    viewport: croppieOptions?.viewport ?? {
                        width: 200,
                        height: 200,
                        type: 'square'
                    }
                })
            })
        }
    }

    /**
     * Creates the result of the cropped input image, sending results to {@link imageUploaded}. Once complete, the
     * model is hidden via {@link setShow}.
     */
    const uploadImage = (): void => {
        let hasPreview = imageUploaded.length == 2

        croppie?.result({format: 'jpeg', type: 'blob', size: 'original'}).then(blob => {
            if (blob != undefined) {

                if (hasPreview) {
                    croppie?.result({format: 'jpeg', type: 'base64'}).then(base64 => {
                        if (base64 != undefined) {
                            setFileBlob(undefined)
                            imageUploaded(blob, base64)
                        }
                    })
                } else {
                    setFileBlob(undefined)
                    imageUploaded(blob, '')
                }
            }

            setShow(false)
        })
    }

    return (
        <Modal className="UploadImageModal" showState={showState} hideModalHook={() => setFileBlob(undefined)} header={hideModal => <>
            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideModal()}></button>
        </>} body={() => <>
            <div className="croppie-wrapper">
                <div ref={uploadDivInit}></div>
            </div>
        </>} footer={hideModal => <>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {
                uploadCancelled?.call(this)
                hideModal();
            }}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={() => uploadImage()}>Upload</button>
        </>}/>
    )
}
