import React, {Fragment, useContext, useEffect, useState} from 'react';
import './AddStaff.scss';
import '../StaffManage.scss';
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import {capitalizeFirst} from "../../../../logic/utility";
import useForm from "../../../../logic/useForm";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";
import {Department, Departments, Staff} from "../../../../logic/objects";
import {UploadedImage, UploadImage, UploadImageModal} from "../../../portable/upload_image/UploadImage";
import ImageService from "../../../../logic/image_service";
import {classes, State} from "../../../../logic/react_utility";
import {FormModal} from "../../../portable/modal/Modal";

/**
 * The inputs that are mapped to the form by an input's name. These are for adding a staff member.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The name of the staff member
     */
    name: string

    /**
     * The email of the staff member
     */
    email: string

    /**
     * The position of the staff member
     */
    position: string

    /**
     * The department the staff member is in
     */
    department: Department

    /**
     * If the staff member is part of eboard.
     */
    eboard: boolean
}

/**
 * The properties for {@link AddStaffModal}.
 */
interface AddStaffModalProps {
    /**
     * The {@link State} for if the modal should be visible or not.
     */
    showState: State<boolean>

    /**
     * A function invoked with a newly created {@link Staff}, to be displayed in the UI.
     */
    addStaff: (staff: Staff) => void
}

/**
 * A popup modal to create a {@link Staff}.
 */
export const AddStaffModal = ({showState, addStaff}: AddStaffModalProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * Expanded state for setting the main modal visible or not.
     */
    const [show, setShow] = showState

    /**
     * The primary uploaded image provided by {@link imageUploaded}.
     */
    const [primaryImage, setPrimaryImage] = useState<UploadedImage | undefined>()

    /**
     * The secondary uploaded image provided by {@link imageUploaded}.
     */
    const [secondaryImage, setSecondaryImage] = useState<UploadedImage | undefined>()
    
    /**
     * Uploads the {@link primaryImage} and {@link secondaryImage}s to the API, applying them to the staff member.
     *
     * @param staff The staff to set the images for
     */
    const uploadImages = (staff: Staff): Promise<void> => {
        return Promise.all([primaryImage, secondaryImage].map((image, index) => {
            if (image == undefined) {
                return undefined
            }
            
            if (index == 0) {
                staff.image = image.preview
            } else {
                staff.secondaryImage = image.preview
            }
            
            return ImageService.uploadStaffImage(requestHandler, staff, index == 0, image.blob)
        }).filter(promise => promise != undefined)).then()
    }

    /**
     * Adds the {@link Staff} from the form {@link inputs}. This invokes {@link uploadImages} after a successful
     * response, as image uploading is processed separately.
     *
     * @param inputs The form inputs of the modal
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        requestHandler.request('/api/staff', {method: 'POST', body: JSON.stringify(inputs)})
            .then(async res => {
                if (res.status == 200) {
                    let json = await res.json()
                    let createdStaff = json as Staff

                    await uploadImages(createdStaff)
                    addStaff(createdStaff)

                    toastHandler.addToast(new ToastInfo(`Created ${inputs.name}`, `Successfully added staff member ${inputs.name}.`))
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while creating a staff member. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }

                setShow(false)
            })
    }

    return (<>
        <FormModal processSubmit={processSubmit} showState={showState} header={hideModal => <>
            <h5 className="modal-title" id="exampleModalLabel">Add a staff member</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideModal()}></button>
        </>} body={() => <>
            <div className="mb-4">
                <input type="text" name="name" placeholder="Name" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <input type="email" name="email" placeholder="Email" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <input type="text" name="position" placeholder="Position" className="form-control underline-input"/>
            </div>
            
            <div className="mb-3">
                <input id="eboard" className="form-check-input" type="checkbox" value="" name="eboard"/>
                <label className="form-check-label ms-3" htmlFor="eboard">
                    E-Board
                </label>
            </div>

            <div className="mb-4">
                <label htmlFor="department" className="mb-1">Department</label>
                <select id="department" name="department" className="form-select" aria-label="Select department">
                    {Departments.map(department =>
                        <option key={department} value={department}>{capitalizeFirst(department)}</option>)}
                </select>
            </div>

            <div className="row mb-4 image-row">
                <div className="col-6">
                    <UploadImage title="Adjust Profile Picture" imagePreview={primaryImage?.preview} imageUploaded={setPrimaryImage}/>
                    <p className="text-center mt-1">Primary Image</p>
                </div>
                <div className="col-6">
                    <UploadImage title="Adjust Profile Picture" imagePreview={secondaryImage?.preview} imageUploaded={setSecondaryImage}/>
                    <p className="text-center mt-1">Hover Image</p>
                </div>
            </div>
        </>} footer={hideModal => <>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            <button type="submit" className="btn btn-primary">Add Staff</button>
        </>}/>
    </>)
}
