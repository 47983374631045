import React, {useContext, useState} from 'react'
import './ForgotPassword.scss'
import useForm from "../../logic/useForm";
import {Button} from "../button/Button";
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import {Result, statusToResult} from "../../logic/requests";

/**
 * The inputs that are mapped to the form by an input's name. These are for requesting a password reset.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The email of the password request.
     */
    email: string
}

/**
 * The forgot password page.
 */
export const ForgotPassword = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The result status of the forgot password request.
     */
    const [result, setResult] = useState(Result.Waiting)

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<FormInputs>()

    /**
     * Requests for the password to be reset.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        let status = await requestHandler.request('/api/auth/reset_password/request', {
            method: 'POST', body: JSON.stringify({
                email: inputs.email
            })
        })

        setResult(statusToResult(status.status))
    }

    return (
        <div className="Login d-flex flex-row justify-content-center">
            <form className="col-8 col-md-4 col-xxl-2 my-5" onSubmit={e => handleSubmit(e, processSubmit)}>
                <div className="mb-4">
                    <input type="email" name="email" placeholder="Email" className="form-control underline-input"/>
                </div>

                <Button submit={true} className="mx-auto mb-3">Reset Password</Button>

                {result == Result.Success && <p className="text-success mt-3">
                    Sent a password reset request, if a user with that account exists
                </p>}

                {(result == Result.Unexpected || result == Result.InternalError) && <p className="text-danger mt-3">
                    An unexpected error occurred while logging in
                </p>}
            </form>
        </div>
    )
}