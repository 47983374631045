import React, {createRef, useContext, useEffect, useState} from 'react'
import './StreamingBar.scss'
import {ArtworkDisplay} from "../portable/artwork_display/ArtworkDisplay";
import {Button} from "../button/Button";
import TrackMultiReceiverContext from "../../contexts/TrackMultiReceiver";
import {Track} from "@witr-radio/music-logger-service";

/**
 * A component representing the bar at the bottom both displaying current stream data and allowing the user to listen
 * to a stream.
 */
export const StreamingBar = () => {
    const trackMultiReceiver = useContext(TrackMultiReceiverContext)

    /**
     * The current stream URL being played (or will be played).
     */
    const [activeStream, setActiveStream] = useState<string>('https://streaming.witr.rit.edu/live-aac-96')

    /**
     * The ID of the {@link trackMultiReceiver} so it can be unsubscribed to on unmount.
     */
    const [listenerId, setListenerId] = useState<number>(-1)

    /**
     * The currently playing track.
     */
    const [currentTrack, setCurrentTrack] = useState<Track | undefined>(undefined)

    /**
     * If the client is currently playing the stream.
     */
    const [playing, setPlaying] = useState<boolean>(false)

    /**
     * If the currently playing stream is underground. If {@link playing} is `false`, this should be ignored.
     */
    const [playingUdg, setPlayingUdg] = useState<boolean>(false)

    /**
     * A reference to the HTML audio element that is playing the stream.
     */
    const audioRef = createRef<HTMLAudioElement>()

    /**
     * Starts listening to tracks playing.
     */
    useEffect(() => {
        // on mount
        setListenerId(trackMultiReceiver.addListener(trackBroadcast => setCurrentTrack(trackBroadcast.track)))

        return () => {
            // on unmount
            trackMultiReceiver.removeListener(listenerId)
        }
    }, [])

    useEffect(() => {
        trackMultiReceiver.setUnderground(playingUdg).then(success => {
            if (success) {
                trackMultiReceiver.trackReceiver.requestCurrentTrack()
            }
        })
    }, [playingUdg])

    /**
     * Toggles the HTML audio element playing, setting the status of {@link playing}.
     */
    const onTogglePlay = (): void => {
        setPlaying(old => {
            let nowPlaying = !old;

            let curr = audioRef.current;
            if (curr != undefined) {
                if (!nowPlaying) {
                    curr.src = ''
                    curr.pause()

                    setTimeout(() => {
                        if (curr != null) {
                            curr.load()

                            curr.src = activeStream
                            curr.load()
                        }
                    }, 500)
                } else {
                    if (curr.src == '') {
                        curr.src = activeStream
                        curr.load()
                    }

                    curr?.play()
                }
            }

            return nowPlaying
        })
    }

    /**
     * Checks if the given stream URL is being played.
     *
     * @param stream The stream URl to check
     * @return If the stream and {@link activeStream} are the same
     */
    const usingStream = (stream: string): boolean => {
        return activeStream == stream
    }

    /**
     * Changes the stream and stops playing the old one.
     *
     * @param stream      The stream URL to change to
     * @param underground If the stream selected is underground
     */
    const clickStream = (stream: string, underground: boolean): void => {
        if (activeStream != stream) {
            audioRef.current?.pause()
            setPlaying(false)
            setActiveStream(stream)
            setPlayingUdg(underground)
        }
    }

    /**
     * A button to represent a single stream quality, with its own stream URL that can be switched to.
     *
     * @param text   The display text of the stream
     * @param stream The URL of the stream
     * @param underground If the stream is underground
     * @return The button element
     */
    const streamButton = (text: string, stream: string, underground: boolean = false): JSX.Element => {
        return <Button onClick={() => clickStream(stream, underground)} size="small" inactive={!usingStream(stream)}>{text}</Button>
    }

    return (
        <div className="StreamingBar">
            <div className="content col-12 d-flex flex-row justify-content-center px-4">
                <div className="info col-5">
                    <ArtworkDisplay image={currentTrack?.getAlbumArt() ?? '/images/default_album.png'} alt="Now Playing" primaryText={currentTrack?.title ?? '-'} secondaryText={currentTrack?.artist ?? ''}/>
                </div>
                <div className="control col-2">
                    {!playing && <span className="material-symbols-outlined" onClick={() => onTogglePlay()}>play_arrow</span>}
                    {playing && <span className="material-symbols-outlined" onClick={() => onTogglePlay()}>pause</span>}
                </div>
                <div className="stream col-5">
                    <audio ref={audioRef} controls={false} autoPlay={false} src={activeStream} className="player"/>

                    <div className="dropdown-selector">
                        <div className="dropdown">
                            <div className="fm">
                                <span>FM</span>
                                <div className="quality-container">
                                    {streamButton('High', 'https://streaming.witr.rit.edu/live-aac-96')}
                                    {streamButton('Medium', 'https://streaming.witr.rit.edu/live-aac-64')}
                                    {streamButton('Legacy', 'https://streaming.witr.rit.edu/live-mp3')}
                                </div>
                            </div>
                            <div className="underground">
                                <span>Underground</span>
                                <div className="quality-container">
                                    {streamButton('High', 'https://streaming.witr.rit.edu/udg-aac-96', true)}
                                    {streamButton('Medium', 'https://streaming.witr.rit.edu/udg-aac-64', true)}
                                    {streamButton('Legacy', 'https://streaming.witr.rit.edu/udg-mp3', true)}
                                </div>
                            </div>
                        </div>

                        <span className="handle">{playingUdg ? 'Underground' : 'FM'} <span className="material-symbols-outlined expand_less">expand_less</span><span className="material-symbols-outlined expand_more">expand_more</span></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
