import React, {Fragment, useContext, useEffect, useState} from 'react'
import './Register.scss'
import useForm from "../../logic/useForm";
import {Button} from "../button/Button";
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import {Result, statusToResult} from "../../logic/requests";
import {RegistrationCompletionInfo} from "../../logic/objects";
import {useNavigate} from "react-router";

/**
 * The inputs that are mapped to the form by an input's name. These are for completing registration.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The password for the account being registered.
     */
    password: string

    /**
     * The 2FA code, if 2FA is being set.
     */
    toptCode: string
}

/**
 * A register page for a user to complete the registration of their account. This requires the `token` query parameter,
 * given by a register confirmation email.
 */
export const Register = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The status of the result.
     */
    const [result, setResult] = useState<Result>(Result.Waiting)

    /**
     * The information regarding the user's registration that they may need before finishing (e.g. the email or name).
     */
    const [completionInfo, setCompletionInfo] = useState<RegistrationCompletionInfo | undefined>()

    /**
     * The React navigation hook.
     */
    const navigate = useNavigate()

    /**
     * The query parameters of the current window.
     */
    const queryParams = new URLSearchParams(window.location.search)

    /**
     * The token, provided by the backend. This is to provide the user with upfront information about the registration,
     * such as their name and email.
     */
    const token = queryParams.get('token') ?? ''

    /**
     * Fetches the {@link RegistrationCompletionInfo} using the {@link token}.
     */
    useEffect(() => {
        requestHandler.request(`/api/auth/registration/completion_info?registrationToken=${encodeURIComponent(token)}`)
            .then(async res => {
                let json = await res.json()
                setCompletionInfo(json as RegistrationCompletionInfo)
            })
    }, [])

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<FormInputs>()

    /**
     * Completes the registration with the form data.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        requestHandler.request('/api/auth/registration/complete', {
            method: 'POST', body: JSON.stringify({
                registrationToken: token,
                password: inputs.password,
                toptCode: inputs.toptCode
            })
        }).then(res => {
            let status = statusToResult(res.status)
            if (status != Result.Success) {
                setResult(status)
            } else {
                requestHandler.setLoggedIn(true)
                navigate('/')
            }
        })
    }

    return (
        <div className="Register d-flex flex-row justify-content-center">
            <form className="col-8 col-md-4 col-xxl-3 my-5" onSubmit={e => handleSubmit(e, processSubmit)}>
                <div className="mb-4">
                    <input type="email" name="email" value={completionInfo?.email} className="form-control underline-input text-muted" disabled={true}/>
                </div>

                <div className="mb-4">
                    <input type="password" name="password" placeholder="Password" autoComplete="current-password" className="form-control underline-input"/>
                </div>

                {completionInfo?.topt != undefined && <Fragment>
                    <div className="mb-4 text-center d-flex flex-column">
                        <img src={completionInfo.topt.qrCode} alt="2FA QR Code" className="qr"/>
                        <p className="mb-0">{completionInfo.topt.secret}</p>
                    </div>

                    <div className="mb-4">
                        <input type="text" name="toptCode" inputMode="numeric" pattern="[0-9]*" maxLength={6} autoComplete="one-time-code" placeholder="6 Digit Code" className="form-control underline-input"/>
                    </div>
                </Fragment>}

                <Button submit={true} className="mx-auto">Register</Button>

                {(result == Result.Unexpected || result == Result.InternalError) && <p className="text-danger mt-3">
                    An unexpected error occurred while logging in
                </p>}

                {result == Result.Unauthorized && <p className="text-danger mt-3">
                    Invalid credentials or 2FA Code
                </p>}
            </form>
        </div>
    )
}