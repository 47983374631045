import React from 'react'
import './ArtworkDisplay.scss'

/**
 * The properties for {@link ArtworkDisplay}.
 */
interface ArtworkDisplayProps {

    /**
     * The image artwork URL
     */
    image: string

    /**
     * The width of the image in pixels
     */
    imageWidth: number

    /**
     * The height of the image in pixels
     */
    imageHeight: number

    /**
     * The image alt text
     */
    alt: string

    /**
     * The upper bold text
     */
    primaryText: string

    /**
     * The lower, less prominent text
     */
    secondaryText: string

    /**
     * Additional classNames to add to the root
     */
    className: string

    /**
     * If defined, the image will be a clickable link to a streaming service.
     */
    imageHref?: string | undefined
}

/**
 * A component to display artwork and text associated with it. This is usually for displaying a song with its artwork,
 * name, and additional text.
 */
export const ArtworkDisplay = (props: ArtworkDisplayProps) => {
    return (
        <div className={'ArtworkDisplay ' + props.className}>
            {props.imageHref == undefined && <img className="image" alt={props.alt} src={props.image}/>}
            {props.imageHref != undefined && <a className="image-link" href={props.imageHref}><img className="image" alt={props.alt} src={props.image}/></a>}
            <div className="text-container">
                <span className="primary-text" title={props.primaryText}>{props.primaryText}</span>
                <span className="secondary-text" title={props.secondaryText}>{props.secondaryText}</span>
            </div>
        </div>
    )
}

/**
 * The default properties for {@link ArtworkDisplayProps}.
 */
ArtworkDisplay.defaultProps = {
    imageWidth: 60,
    imageHeight: 60,
    className: ''
}
