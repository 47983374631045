import React from 'react'
import './Support.scss'
import useForm from "../../../logic/useForm";

export const Support = () => {
    /**
     * The form data types.
     */
    type FormInputs = {
        name: string
        equipment: string
        issue: string
    }

    const {handleSubmit} = useForm<FormInputs>()

    const processSubmit = async (inputs: FormInputs) => {
        console.log('Submitting support ticket:');
        console.log(inputs);
    }

    return (
        <div className="Support">
            <h2>Someone's getting fired!</h2>
            <form onSubmit={e => handleSubmit(e, processSubmit)}>
                <label>
                    <p>Your Name</p>
                    <input type="text" name="name"/>
                </label>

                <label>
                    <p>Piece of Equipment</p>
                    <input type="text" name="equipment"/>
                </label>

                <label>
                    <p>Issue</p>
                    <textarea name="issue" cols={50} rows={10}/>
                </label>

                <button type="submit" className="submit">Submit</button>
            </form>
        </div>
    )
}
