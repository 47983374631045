import React from 'react'
import './Listeners.scss'
import {useParams} from "react-router";

/**
 * A graph of broadcastListeners over time for whatever given studio is supplied in the URL.
 */
export const Listeners = () => {
    const { studio } = useParams<'studio'>();

    return (
        <div className="Listeners">
            <h2>Listeners for {studio}</h2>
            <p>I'm not doing graphing and stuff yet until I get a mockup of what it's supposed to look like</p>
        </div>
    )
}
