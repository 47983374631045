import React, {createRef, useEffect, useState} from 'react'
import './SideScroll.scss'
import {classes} from "../../../logic/react_utility";

interface SideScrollProps {

    /**
     * The width of the item in pixels
     */
    itemWidth: number

    /**
     * The items to display
     */
    items: JSX.Element[]
}

export const SideScroll = (props: SideScrollProps) => {
    const [rightOverflow, setRightOverflow] = useState<boolean>(false);
    const [leftOverflow, setLeftOverflow] = useState<boolean>(false);
    const itemsRef = createRef<HTMLDivElement>();

    useEffect(() => {
        checkScroll()
    }, [props.itemWidth, props.items])

    function navLeft() {
        let curr = itemsRef.current!
        let remaining = curr.scrollLeft % props.itemWidth

        let scrollDistance = props.itemWidth
        if (remaining != 0) {
            scrollDistance = remaining
        }

        curr.scrollBy({left: -scrollDistance, behavior: 'smooth'})
    }

    function navRight() {
        let curr = itemsRef.current!

        curr.scrollBy({left: props.itemWidth, behavior: 'smooth'})
    }

    function onScroll(e: React.UIEvent<HTMLDivElement>) {
        checkScroll()
    }

    function checkScroll() {
        let target = itemsRef.current!
        let newRightOverflow = !isOnRightEdge()
        if (newRightOverflow != rightOverflow) {
            setRightOverflow(newRightOverflow)
        }

        let newLeftOverflow = target.scrollLeft != 0
        if (newLeftOverflow != leftOverflow) {
            setLeftOverflow(newLeftOverflow)
        }
    }

    function isOnRightEdge(): boolean {
        let target = itemsRef.current!
        let boundWidth = Math.max(target.offsetWidth, props.itemWidth)
        return isWithinThreshold(target.scrollLeft + boundWidth, target.scrollWidth, 5)
    }

    return (
        <div className="SideScroll">
            <div ref={itemsRef} className={classes(['overflow-left', leftOverflow], ['overflow-right', rightOverflow], 'items', 'col-12', 'mx-0', 'd-flex', 'flex-nowrap')} onScroll={onScroll}>
                {props.items}
            </div>

            {leftOverflow && <div className="arrow left-arrow">
                <span className="material-symbols-outlined" onClick={(e) => navLeft()}>chevron_right</span>
            </div>}

            {rightOverflow && <div className="arrow right-arrow">
                <span className="material-symbols-outlined" onClick={(e) => navRight()}>chevron_right</span>
            </div>}
        </div>
    )
}

function isWithinThreshold(x: number, target: number, threshold: number): boolean {
    return Math.abs(x - target) <= threshold
}
