import React from 'react'
import './Upload.scss'
import {useParams} from "react-router";
import useForm from "../../../../logic/useForm";
import {v4 as uuidv4} from 'uuid';
import {File, files} from "../Files"

/**
 * Uploading, or modifying an uploaded file.
 * Currently unused.
 */
export const Upload = () => {
    const {id} = useParams<'id'>()
    let newFile = id == undefined
    let file = newFile ? new File(uuidv4(), "", "", 0, false) : files.find(f => f.id == id)

    /**
     * The form data types.
     */
    type FormInputs = {
        name: string
        restricted: boolean
        // TODO: File contents! Waiting for backend for that though
    }

    const {handleSubmit} = useForm<FormInputs>()

    const processSubmit = async (inputs: FormInputs) => {
        console.log('Updating/uploading file:');
        console.log(inputs);
    }

    if (file == undefined) {
        return <span>Error, I'll fix later</span>
    }

    return (
        <div className="Upload">
            {newFile && <h2>Uploading New File</h2>}
            {!newFile && <h2>Modifying {file.name}</h2>}

            <form onSubmit={e => handleSubmit(e, processSubmit)}>
                <label>
                    <p>Name</p>
                    <input type="text" name="name" defaultValue={file.name}/>
                </label>

                <label>
                    <p>Restricted</p>
                    <input type="checkbox" name="restricted" defaultChecked={file.restricted}/>
                </label>

                <p>TODO: File upload when backend is ready</p>

                <button className="submit" type="submit">
                    {newFile ? 'Upload File' : 'Modify File'}
                </button>
            </form>
        </div>
    )
}
