import React, {useContext, useState} from 'react';
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import {capitalizeFirst} from "../../../../logic/utility";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";
import {Departments, ShowCreate, ShowDescription, Staff} from "../../../../logic/objects";
import {UploadedImage, UploadImage} from "../../../portable/upload_image/UploadImage";
import ImageService from "../../../../logic/image_service";
import {State} from "../../../../logic/react_utility";
import {FormModal} from "../../../portable/modal/Modal";
import {DOMUserSelector, UserSelector} from "../../../portable/user_selector/UserSelector";
import {Role} from "../../../../logic/self";

/**
 * The properties for {@link CreateShowModal}.
 */
interface CreateShowModalProps {
    /**
     * The {@link State} for if the modal should be visible or not.
     */
    showState: State<boolean>

    /**
     * A function invoked with a newly created {@link ShowDescription}, to be displayed in the UI.
     */
    addShow: (show: ShowDescription) => void
}

/**
 * A popup modal to create a {@link Staff}.
 */
export const CreateShowModal = ({showState, addShow}: CreateShowModalProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * Expanded state for setting the main modal visible or not.
     */
    const [show, setShow] = showState

    /**
     * The primary uploaded image provided by {@link imageUploaded}.
     */
    const [image, setImage] = useState<UploadedImage | undefined>()

    /**
     * Sets the {@link image} after an image has been selected and cropped.
     *
     * @param imageBlob     The {@link Blob} of the image
     * @param base64Preview The base64 preview of the image
     */
    const setSelectedImage = (imageBlob: Blob, base64Preview: string): void => {
        setImage({blob: imageBlob, preview: base64Preview})
    }

    /**
     * Uploads the {@link image} to the API, applying them to the show.
     *
     * @param show The show to set the images for
     */
    const uploadImages = (show: ShowDescription): Promise<void> => {
        if (image == undefined) {
            return Promise.resolve()
        }

        return ImageService.uploadShowImage(requestHandler, show, image.blob, url => show.image = url)
    }

    /**
     * Adds the {@link Staff} from the form {@link inputs}. This invokes {@link uploadImages} after a successful
     * response, as image uploading is processed separately.
     *
     * @param inputs The form inputs of the modal
     */
    const processSubmit = async (inputs: ShowCreate): Promise<void> => {
        // Don't want to send over the image; this will be uploaded after the creation
        const {image, ...inputsImageRemoved} = inputs
        
        requestHandler.request('/api/show', {method: 'POST', body: JSON.stringify(inputsImageRemoved)})
            .then(async res => {
                if (res.status == 200) {
                    let json = await res.json()
                    let createdStaff = json as ShowDescription

                    await uploadImages(createdStaff)
                    addShow(createdStaff)

                    toastHandler.addToast(new ToastInfo(`Created ${inputs.name}`, `Successfully added the show ${inputs.name}.`))
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while creating a show. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }

                setShow(false)
            })
    }

    return (<>
        <FormModal processSubmit={processSubmit} showState={showState} header={hideModal => <>
            <h5 className="modal-title" id="exampleModalLabel">Add a show</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideModal()}></button>
        </>} body={() => <>
            <div className="mb-4">
                <input type="text" name="name" placeholder="Name" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <input type="text" name="description" placeholder="Show description" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <p className="mb-1">Owners</p>
                <DOMUserSelector name="owners" minimumRole={Role.dj}/>
            </div>

            <div className="mb-4">
                <input type="text" name="position" placeholder="e.g. 8 PM - 10 PM" className="form-control underline-input"/>
            </div>

            <div className="row mb-4 image-row">
                <div className="col-6">
                    <UploadImage title="Adjust Profile Picture" imagePreview={image?.preview} imageUploaded={setImage}/>
                    <p className="text-center mt-1">Cover Image</p>
                </div>
            </div>
        </>} footer={hideModal => <>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            <button type="submit" className="btn btn-primary">Add Show</button>
        </>}/>
    </>)
}
