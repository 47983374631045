import React from 'react';
import './NotFound.scss';

/**
 * The 404 page of the website.
 */
export default class NotFound extends React.Component {

    render() {
        return (
            <div className="NotFound">
                <div className="centering">
                    <h1>404</h1>
                    <p>Uh oh! :(</p>
                </div>
            </div>
        );
    }
}
