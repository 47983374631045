import React, {createRef, useContext, useState} from 'react';
import './Header.scss';
import {Link, NavLink} from "react-router-dom";
import {shows} from "../show/Show";
import {ReactComponent as Logo} from '../../logo.svg';
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import {classes} from "../../logic/react_utility";

/**
 * A representation of a navigation link that can be displayed in a dropdown.
 */
interface DropdownLink {
    /**
     * The unique name of the link. This is used for identification only, and is not displayed.
     */
    name: string

    /**
     * The supplier of the link. This will be directly placed in the vertical dropdown element.
     */
    supplier: () => JSX.Element
}

/**
 * The header component of the whole website. This includes elements such as the sticky audio player and navigation.
 */
export const Header = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * If the nav bar is visible while the mobile nav is displayed.
     */
    const [visible, setVisible] = useState<boolean>(false)

    /**
     * If the `more` dropdown is expanded and showing links.
     */
    const [moreShowing, setMoreShowing] = useState<boolean>(false)

    /**
     * If the left navbar's ref has been created already, to ensure initialization only occurs once.
     */
    const [navLoaded, setNavLoaded] = useState<boolean>(false)

    /**
     * The names of links that are hidden and should be shown in the `more` dropdown.
     */
    const [hiddenNavs, setHiddenNavs] = useState<string[]>([])

    /**
     * If icons have been loaded. This is set on a timer, and all icons are invisible if `false`.
     */
    const [iconsLoaded, setIconsLoaded] = useState<boolean>(false)

    /**
     * Initializes resizing listening and ensures the nav bar is properly displayed. This is invoked when the
     * `.left-nav` element is initialized.
     *
     * @param element The nav element with the `.left-nav` class
     */
    const initNav = (element: HTMLUListElement | null): void => {
        if (element == null || navLoaded) {
            return
        }

        setNavLoaded(true)

        /**
         * Recalculates {@link hiddenNavs} upon window resize.
         */
        const onResize = (): void => {
            let children = element.children
            if (children != undefined) {
                let filtered = [...children].filter(child => child?.getBoundingClientRect().y ?? 0 > 0)
                    .filter(e => e.hasAttribute('data-path'))
                    .map(e => e.getAttribute('data-path')!)

                setHiddenNavs(filtered)
            }
        }

        window.addEventListener('resize', onResize)

        // Initial resize to get currently hiding items
        onResize()

        // Do another 100ms later to take things shifted by the "More" item being put in
        setTimeout(onResize, 100)

        // Resize again just for any additional resizing done by slow browser. This is a poor solution for the "More"
        // dropdown show up on pages that don't need it.
        setTimeout(onResize, 1000)

        // Assume icons are probably loaded after 200ms
        setTimeout(() => setIconsLoaded(true), 200)
    }

    /**
     * Returns a navigation bar link pointing to a React path. Intended to be in the main `.nav-content` of the navigation bar.
     *
     * @param name      The name of the link
     * @param path      The router path this goes to
     * @param className Any classes to add to the component
     */
    const navLink = (name: string, path: string, className: string = ''): JSX.Element => {
        return (
            <li key={path} data-path={path} className={className}>
                <NavLink className={({isActive}) => isActive ? 'active' : ''} to={path} onClick={() => setVisible(false)}>{name}</NavLink>
            </li>
        );
    }


    /**
     * Returns a navigation bar link pointing to a URL. Intended to be in the main `.nav-content` of the navigation bar.
     *
     * @param name      The name of the navigation link
     * @param className Any classes to add to the component
     * @param src       The URL being linked to
     */
    const aNavLink = (name: string, className: string, src: string): JSX.Element => {
        return (
            <li key={src} data-path={name} className={className}><a href={src}>{name}</a></li>
        );
    }

    /**
     * Creates a dropdown displaying all nav links that don't fit on the screen.
     *
     * @param links   All possible links to display
     * @param visible If the actual dropdown button is visible
     * @return The created dropdown element
     */
    const moreDropdown = (links: DropdownLink[], visible: boolean): JSX.Element => {
        return (
            <li className={classes('dropdown d-none d-md-block', ['expanded', moreShowing], ['visible', visible])} onClick={visible ? () => setMoreShowing(old => !old) : () => {}}>
                <span className="more">More <span className="material-symbols-outlined inline-icon">arrow_drop_down</span></span>
                <ul className={'dropdown-content'}>
                    {visible && links.filter(link => hiddenNavs.includes(link.name)).map(link => link.supplier())}
                </ul>
            </li>
        );
    }

    return (
        <div className={classes('Header', ['icons-loaded', iconsLoaded])}>
            <span className="material-symbols-outlined hamburger d-block d-md-none" onClick={() => setVisible(true)}>menu</span>
            <div className={classes('content-wrapper nav', ['visible', visible])}>
                <div className="content nav-content">
                    <div className="close-button" onClick={() => setVisible(false)}>&times;</div>
                    <Link to="/"><Logo/></Link>
                    <ul ref={initNav} className="d-flex flex-column flex-md-row ms-0 mr-2 left-nav">
                        {navLink('About', 'about')}
                        {navLink('Shows', 'shows')}
                        {navLink('Songs', 'songs')}
                        {navLink('Contact', 'contact')}
                        {aNavLink('Donate', 'accentColor', 'https://www.rit.edu/giving/makeagift/')}
                    </ul>
                    <ul className="d-flex flex-column flex-md-row ms-0 ms-md-auto mr-2 right-nav">
                        {moreDropdown([
                            {name: 'about', supplier: () => navLink('About', 'about')},
                            {name: 'shows', supplier: () => navLink('Shows', 'shows')},
                            {name: 'songs', supplier: () => navLink('Songs', 'songs')},
                            {name: 'contact', supplier: () => navLink('Contact', 'contact')},
                            {name: 'Donate', supplier: () => aNavLink('Donate', 'accentColor', 'https://www.rit.edu/giving/makeagift/')}
                        ], hiddenNavs.length > 0)}

                        {requestHandler.loggedIn &&
                            <li key="logout" className="right">
                                <span className="logout" onClick={() => requestHandler.logOut()}>Logout</span></li>
                        }

                        {requestHandler.loggedIn &&
                            navLink('Account', 'account', 'ms-md-auto')
                        }

                        {(requestHandler.loggedIn && requestHandler.self?.isAdmin()) &&
                            navLink('Admin Panel', 'admin', 'ms-md-auto me-0 me-md-3')
                        }

                        {!requestHandler.loggedIn &&
                            navLink('Login', 'login', 'ms-md-auto me-0 me-md-3')
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}
