import React from 'react';
import './Unauthorized.scss';

/**
 * The 403 page of the website.
 */
export default class Unauthorized extends React.Component {

    render() {
        return (
            <div className="NotFound">
                <div className="centering">
                    <h1>403</h1>
                    <p>You don't have access to this page</p>
                </div>
            </div>
        );
    }
}
