import {ToastHandler, ToastInfo} from "../contexts/ToastHandler";
import {RequestHandler} from "../contexts/RequestHandler";
import {DetailedPost, Post, ShowDescription, Staff} from "./objects";

/**
 * A service to handle image uploading.
 */
const ImageService = {
    /**
     * Uploads a given image blob, and sets it as the given staff member's profile.
     *
     * @param requestHandler The {@link RequestHandler} instance
     * @param staff The staff member to upload this to
     * @param primary If this is a primary image (`false` if secondary)
     * @param image The image blob to upload
     * @param toastHandler The optional {@link ToastHandler} instance. If set, a toast will be sent out if the request
     *                     was successful
     * @param updateLocalImage An optional callback invoked after a successful response, to provide the new image URL
     * @return The promise of the upload, regardless of success status
     */
    uploadStaffImage: function (requestHandler: RequestHandler, staff: Staff, primary: boolean, image: Blob, toastHandler?: ToastHandler, updateLocalImage?: (imagePath: string, primary: boolean) => void): Promise<void> {
        let formData = new FormData();

        formData.append('image', image);

        return requestHandler.request(`/api/staff/${staff.id}/upload_image?primary=${primary}`, {
            autoContentType: true, method: 'POST', body: formData
        }).then(async res => {
            let json = await res.json();

            if (res.status == 200) {
                toastHandler?.addToast(new ToastInfo('Upload Successful', `Successfully updated ${staff.name}'s profile picture`))

                updateLocalImage?.call(this, json['image'], primary)
            }
        })
    },
    
    /**
     * Uploads a given image blob, and sets it as the given staff member's profile.
     *
     * @param requestHandler The {@link RequestHandler} instance
     * @param show The show to upload the image for
     * @param image The image blob to upload
     * @param updateLocalImage An optional callback invoked after a successful response, to provide the new image URL
     * @return The promise of the upload, regardless of success status
     */
    uploadShowImage: function (requestHandler: RequestHandler, show: ShowDescription, image: Blob, updateLocalImage?: (imagePath: string) => void): Promise<void> {
        let formData = new FormData();

        formData.append('image', image);

        return requestHandler.request(`/api/show/${show.id}/upload_image`, {
            autoContentType: true, method: 'POST', body: formData
        }).then(async res => {
            let json = await res.json();

            if (res.status == 200) {
                updateLocalImage?.call(this, json['image'])
            }
        })
    },
    
    /**
     * Uploads a given image blob, and sets it as the post's cover image.
     *
     * @param requestHandler The {@link RequestHandler} instance
     * @param showId The ID of the show to upload the image for
     * @param postId The ID of the specific post to upload the image for
     * @param image The image blob to upload
     * @param updateLocalImage An optional callback invoked after a successful response, to provide the new image URL
     * @return The promise of the upload, regardless of success status
     */
    uploadPostImage: function (requestHandler: RequestHandler, showId: string, postId: string, image: Blob, updateLocalImage?: (imagePath: string) => void): Promise<void> {
        let formData = new FormData();

        formData.append('image', image);

        return requestHandler.request(`/api/show/${showId}/post/${postId}/upload_image`, {
            autoContentType: true, method: 'POST', body: formData
        }).then(async res => {
            let json = await res.json();

            if (res.status == 200) {
                updateLocalImage?.call(this, json['image'])
            }
        })
    }
}

export default ImageService
