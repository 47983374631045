import React, {Fragment, useContext, useEffect, useState} from 'react';
import './Contact.scss';
import {RequestBox} from "../sidebar/request_box/RequestBox";
import {Button} from "../button/Button";
import {Staff} from "../../logic/objects";
import RequestHandlerContext from "../../contexts/RequestHandler";
import {classes} from "../../logic/react_utility";
import Obfuscate from "react-obfuscate";

/**
 * The department groups staff are split up into.
 */
const DEPARTMENTS = ['Engineering', 'Programming', 'Recording', 'General', 'Business']

/**
 * A page with information to contact staff or the station in general.
 */
export const Contact = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The staff being listed. In the UI, they are grouped by {@link Staff.department}.
     */
    const [staff, setStaff] = useState<Staff[]>([])

    /**
     * Initially lists staff.
     */
    useEffect(() => {
        listStaff()
    }, [])

    /**
     * Fetches the {@link Staff} being displayed.
     */
    const listStaff = (): void => {
        requestHandler.request('/api/staff/list')
            .then(async res => {
                setStaff((await res.json()) as Staff[])
            })
    }

    /**
     * Returns an element to display an entire department with their given staff members.
     * 
     * @param department The department name
     * @param members    The staff members in the department to display
     * @return An element to display the entire department
     */
    const displayDepartment = (department: string, members: Staff[]): JSX.Element => <Fragment>
        <h3 className="secondary-title mt-3">{department}</h3>
        <div className="people d-flex flex-column flex-wrap flex-md-row gap-4 gap-md-0">
            {members.map(displaySingleStaff)}
        </div>
    </Fragment>

    /**
     * Displays a single staff member, returning its element.
     *
     * @param person The {@link Staff} to display
     * @return The div element to display
     */
    const displaySingleStaff = (person: Staff): JSX.Element => {
        let image = (person.image?.length == 0 ?? true) ? '/images/staff/default.jpg' : person.image
        let secondaryImage = person.secondaryImage

        return (
            <div className="contact d-flex flex-row col-12 col-md-6 col-xxl-4 py-md-2">
                {secondaryImage != undefined && <link rel="preload" as="image" href={person.secondaryImage}/>}
                <img className={classes('image', ['has-secondary', secondaryImage != undefined])} alt={person.name} src={image} style={{'--secondary-image': `url(${secondaryImage})`} as React.CSSProperties}/>
                <div className="text-container d-flex flex-column justify-content-center ms-3">
                    <span className="person">{person.name}</span>
                    <span className="secondary-text">{person.position}</span>
                    <span className="secondary-text"><Obfuscate email={person.email}/></span>
                </div>
            </div>
        );
    }

    return (
        <div className="Contact">
            <h3 className="primary-title mb-0">Staff</h3>

            {displayDepartment('E-Board', staff.filter(person => person.eboard))}
            
            {DEPARTMENTS.map(department => displayDepartment(department, staff.filter(person => !person.eboard).filter(person => department.toLowerCase() == person.department)))}

            <div className="other-contact d-flex flex-wrap flex-column flex-md-row justify-content-between mt-3 mt-md-5">
                <div className="request col-12 col-md-6 col-xl-4 col-xxl-3 pe-md-3">
                    <RequestBox/>
                </div>
                <div className="donate col-12 col-md-6 col-xl-4 col-xxl-3 mt-4 mt-md-0 ps-md-3">
                    <h3 className="primary-title mb-2">Donate to WITR</h3>
                    <p className="mb-3 ">For donation inquiries, please
                        contact <Obfuscate email="radio@witr.rit.edu"/></p>
                    <div className="button-bar d-flex justify-content-center">
                        <a href="https://www.rit.edu/giving/makeagift/" target="_blank" className="btn btn-primary">Donate</a>
                    </div>
                </div>
                <div className="mailing col-12 col-md-6 col-xl-4 col-xxl-3 mt-4 mt-xl-0 pe-md-3 pe-xl-0 ps-xl-3">
                    <h3 className="primary-title mb-2">Our Mailing Address</h3>
                    <p className="my-0">WITR Radio<br/>
                        32 Lomb Memorial Drive<br/>
                        Rohester, NY 14623-0563</p>
                </div>
                <div className="social col-12 col-md-6 col-xl-4 col-xxl-3 mt-4 mt-xl-5 mt-xxl-0 ps-md-3 ps-xl-0 ps-xxl-3">
                    <h3 className="primary-title mb-2">Social Media</h3>
                    <a href="https://www.instagram.com/witr897/">Instagram</a>
                    <a href="https://twitter.com/witr897">Twitter</a>
                    <a href="https://www.facebook.com/WITR897/">Facebook</a>
                </div>
            </div>
        </div>
    );
}
