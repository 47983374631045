import {ElevatedUser, User} from "./objects";

/**
 * Formats a Date to YYY-MM-DD
 * @param date The Date to format
 */
export function formatDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`
}

/**
 * Creates an array range starting from offset, with a specified length
 * @param length The length of the array
 * @param offset The number to add to each element
 */
export function range(length: number, offset: number = 0): number[] {
    return Array.from(Array(length).keys()).map(i => i + offset)
}

/**
 * Capitalizes the first character of the string safely.
 *
 * @param string The string to capitalize
 */
export function capitalizeFirst(string: string): string {
    if (string.length < 2) {
        return string.toUpperCase()
    }

    return `${string.substring(0, 1).toUpperCase()}${string.substring(1)}`
}

/**
 * Gets the display name or the full name of the user.
 *
 * @param user The user to get the name of
 */
export function getDisplayName(user: ElevatedUser | User): string {
    if (user.displayName != undefined) {
        return user.displayName
    }

    if (user.lastName == undefined) {
        return user.firstName
    }

    return `${user.firstName} ${user.lastName}`
}
