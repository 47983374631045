import './EditShow.scss';
import {useParams} from "react-router";
import {ShowCreate, ShowDescription, User} from "../../../../logic/objects";
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {UploadImage} from "../../../portable/upload_image/UploadImage";
import ImageService from "../../../../logic/image_service";
import {UserSelector} from "../../../portable/user_selector/UserSelector";
import {Role} from "../../../../logic/self";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";

type ShowModify = (old: ShowDescription) => ShowDescription

/**
 * A page to edit a specific {@link ShowDescription}.
 */
export const EditShow = () => {
    const requestHandler = useContext(RequestHandlerContext)
    const {id} = useParams<'id'>()

    const [show, setShow] = useState<ShowDescription | undefined>(undefined)

    useEffect(() => {
        requestHandler.request(`/api/show/${id}`).then(async res => {
            if (res.status == 200) {
                let json = await res.json()
                setShow(json as ShowDescription)
            } else {
                console.error(`There was a problem getting the show! Status code: ${res.status}`);
            }
        })
    }, [])

    /**
     * Refreshes the {@link show} with the given modifying function by cloning it and applying changes.
     * 
     * @param modify A function called with the current {@link show}, which should return the new object
     */
    const refreshState = (modify: ShowModify): void => {
        setShow(old => modify({...old!} as ShowDescription))
    }

    return (
        <div className="EditShow">
            {show == undefined ? <p>Fetching show...</p> : <EditShowBody show={show} refreshState={refreshState}/>}
        </div>
    )
}

/**
 * Properties passed to the {@link EditShowBody}.
 */
interface EditShowBodyProps {

    /**
     * The show being edited.
     */
    show: ShowDescription

    /**
     * Refreshes the {@link show} state. This does not modify anything.
     */
    refreshState: (modify: ShowModify) => void
}

/**
 * An inner component to simplify editing of a show, rendered once a {@link ShowDescription} object is fetched from the
 * page's ID.
 */
const EditShowBody = ({show, refreshState}: EditShowBodyProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)
    const [changedImage, setChangedImage] = useState<Blob | undefined>()

    /**
     * Updates the {@link show} state's name.
     *
     * @param name The new name
     */
    const updateName = (name: string): void => {
        refreshState(show => {
            show.name = name
            return show
        })
    }

    /**
     * Updates the {@link show} state's description.
     * 
     * @param description The new description
     */
    const updateDescription = (description: string): void => {
        refreshState(show => {
            show.description = description
            return show
        })
    }

    /**
     * Updates the {@link show} state's display time.
     * 
     * @param time The new display time
     */
    const updateTime = (time: string): void => {
        refreshState(show => {
            show.time = time
            return show
        })
    }

    /**
     * Updates the show's owners and refreshes the state.
     *
     * @param show   The show object to update
     * @param owners The new owners of the post
     */
    const updateOwners = (show: ShowDescription, owners: User[]): void => {
        refreshState(show => {
            show.owners = owners
            return show
        })
    }

    /**
     * Notifies the component that a new image has been selected. This blob will be uploaded to the server upon
     * submitting the form.
     * 
     * @param imageBlob The {@link Blob} of the image
     * @param base64Preview The base64 preview of the image
     */
    const uploadImage = (imageBlob: Blob, base64Preview: string): void => {
        setChangedImage(imageBlob)
        refreshState(show => {
            show.image = base64Preview
            return show
        })
    }

    /**
     * Uploads all new data to the server.
     */
    const saveShow = async (): Promise<void> => {
        if (changedImage != undefined) {
            await ImageService.uploadShowImage(requestHandler, show, changedImage)
        }
        
        await requestHandler.request(`/api/show/${show.id}`, {method: 'POST', body: JSON.stringify({
                name: show.name,
                description: show.description,
                time: show.time,
                owners: show.owners.map(owner => owner.id)
            } as ShowCreate)})

        toastHandler.addToast(new ToastInfo(`Saved Show`, `Successfully saved ${show.name}.`))
    }

    return (<>
        <div className="d-flex flex-column flex-md-row">
            <div className="col-12 col-md-5 col-xxl-4 mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" name="name" id="name" placeholder="Show name" className="form-control underline-input mb-3" value={show.name} onChange={e => updateName(e.target.value)}/>
            
                <label htmlFor="description" className="form-label">Short description</label>
                <input type="text" id="description" placeholder="Show description" className="form-control underline-input" value={show.description} onChange={e => updateDescription(e.target.value)}/>
            </div>
            
            <div className="image-upload-wrapper col-12 col-md-7 col-xxl-8 mb-3">
                <UploadImage className="ps-md-4 ps-lg-5" title="Adjust Show Image" imagePreview={show.image} imageUploaded={uploadImage}/>
            </div>
        </div>

        <div className="d-flex flex-column">
            <div className="col-12 col-md-5 col-xxl-4 mb-3">
                <div className="mb-3">
                    <p className="mb-1">Owners</p>
                    <UserSelector selectedUsers={show.owners} setSelectedUsers={setFunction => updateOwners(show, setFunction(show.owners))} minimumRole={Role.dj}/>
                </div>
                
                <label htmlFor="name" className="form-label">Displaying Time</label><br/>
                <small className="text-muted mb-1">This is separate from the scheduled time. This allows for a little more play in what is shown versus all scheduled times.</small>
                <input type="text" name="name" id="name" placeholder="Show name" className="form-control underline-input mb-3" value={show.time} onChange={e => updateTime(e.target.value)}/>
            </div>
        </div>
        

        <div className="col-12 mt-3">
            <button className="btn btn-primary me-2" onClick={() => saveShow()}>Save</button>
            <Link to="/admin/shows" className="btn btn-secondary">Cancel</Link>
        </div>
    </>)
}
