import {ChangeEvent, DOMAttributes, FormEvent, useContext, useState} from 'react';
import RequestHandlerContext from "../contexts/RequestHandler";
import {ElevatedUser, Listable, PageLinks} from "./objects";
import {PaginationOptions} from "../component/portable/lister/Lister";

/**
 * Properties returned by the hook to manage the notice.
 */
export interface ReCaptchaNoticeControl {
    /**
     * Passed to the {@link ReCaptchaNotice} to indicate it should be shown.
     */
    noticeVisible: boolean

    /**
     * Attributes that should be spread to the target (usually a form) to handle focus/blue events.
     */
    formAttributes: DOMAttributes<any>
}

/**
 * A hook for handling the showing/hiding of the {@link ReCaptchaNotice} component based on focus/blur events.
 */
export default function useReCaptchaNotice(): ReCaptchaNoticeControl {
    const [visible, setVisible] = useState<boolean>(false)

    return {
        noticeVisible: visible,
        formAttributes: {
            onFocus: () => setVisible(true),
            onBlur: () => setVisible(false)
        }
    }
}
