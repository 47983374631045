import React, {useContext, useEffect, useState} from 'react';
import './Shows.scss';
import {combineNames, Paginated, ShowDescription} from "../../logic/objects";
import RequestHandlerContext from "../../contexts/RequestHandler";

/**
 * A page showing all the DJ shows.
 */
export const Shows = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The shows being displayed.
     */
    const [shows, setShows] = useState<ShowDescription[]>([])

    /**
     * Listed the shows, updating {@link shows}.
     */
    useEffect(() => {
        requestHandler.request('/api/show/list?count=20&page=0')
            .then(async res => {
                let json = await res.json()
                let paginated = json as Paginated<ShowDescription>
                setShows(paginated.items)
            })
    }, [])

    /**
     * Gets a component to display a {@link ShowDescription}.
     *
     * @param show The show to display
     * @return The show display
     */
    const displayShow = (show: ShowDescription): JSX.Element => {
        return (
            <div className="show">
                <img src={show.image} className="image" alt="Show image"/>
                <span className="name">{show.name}</span>
                <span className="dj">{combineNames(show.owners)}</span>
                <span className="time">{show.time}</span>
            </div>
        )
    }

    return (
        <div className="Shows">
            <h1 className="main-title">Check out our DJ shows<br/>and podcasts!</h1>
            <div className="items">
                {shows.map(displayShow)}
            </div>
        </div>
    );
}
