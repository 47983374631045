/**
 * The result status of an HTTP response.
 */
export enum Result {
    /**
     * Used as a placeholder (e.g. in a state) so Result doesn't need to be undefined.
     * No request method should ever use this
     */
    Waiting,

    /**
     * If the request was successful. This usually indicated that more data is available.
     */
    Success,

    /**
     * If the provided credentials are invalid
     */
    Unauthorized,

    /**
     * If a 500 ISE occurs
     */
    InternalError,

    /**
     * If the request is being rate limited, meaning a status code of 429.
     */
    RateLimited,

    /**
     * If the error code is anything but a 200, 401, or 5xx
     */
    Unexpected
}

/**
 * Methods relating to Result.
 */
// export namespace Result {
//     /**
//      * Returns true if the given Result is NOT of type Success or Waiting
//      * @param result The Result to test
//      * @returns If this Result is associated with an error status
//      */
//     export function isError(result: Result) {
//         return result > Result.Success
//     }
// }

/**
 * Checks if the given status number is within [500, 599]
 *
 * @param status The status code
 * @return If the status is an Internal Server Error
 */
export function isISE(status: number): boolean {
    return status >= 500 && status < 600
}

/**
 * Converts a status code to a Request.
 * 401: Unauthorized
 * [500, 600): InternalError
 * 200: Success
 * *: Unexpected
 *
 * @param status The status code
 * @return The matching Result
 */
export function statusToResult(status: number): Result {
    if (status == 401) {
        return Result.Unauthorized
    }

    if (isISE(status)) {
        return Result.InternalError
    }

    if (status != 200) {
        return Result.Unexpected
    }

    return Result.Success
}
