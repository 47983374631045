import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {BrowserRouter} from "react-router-dom";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {App} from "./component/App";
import {RequestHandlerProvider} from "./contexts/RequestHandler";

// If this file is not found, run `npm run style`
import './bootstrap/dist/bootstrap.min.css';
import {ToastHandlerProvider} from "./contexts/ToastHandler";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,700,1,0&display=block" />
            <div className="flex-wrapper d-flex flex-column">
                <ToastHandlerProvider>
                    <RequestHandlerProvider>
                        <App/>
                    </RequestHandlerProvider>
                </ToastHandlerProvider>
            </div>
            {/*<script src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`}></script>*/}
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
