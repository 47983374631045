import React, {useContext, useEffect, useState} from 'react';
import './SystemPost.scss';
import RequestHandlerContext from "../../../contexts/RequestHandler";
import {combineNames, DetailedPost, PageLinks, Post, PostCreate, User} from "../../../logic/objects";
import {Button} from "../../button/Button";
import DOMPurify from "dompurify";
import {marked} from "marked";
import EasyMDE from "easymde";
import {DropdownDate} from "../../portable/dropdown_date/DropdownDate";
import SearchDateContext from "../../../contexts/SearchDate";
import {ToastHandlerContext, ToastInfo} from "../../../contexts/ToastHandler";
import moment from "moment/moment";
import {UserSelector} from "../../portable/user_selector/UserSelector";
import {Role} from "../../../logic/self";
import {UploadedImage, UploadImage} from "../../portable/upload_image/UploadImage";
import {CroppieOptions} from "croppie";
import ImageService from "../../../logic/image_service";

/**
 * The initial URL to request the first page of system posts.
 */
const INITIAL_URL = `/api/show/system/post/list?count=20&page=0`;

/**
 * A constant value the local featured post UUID may have, to check if the created post should be set as featured.
 */
type CreatingFeaturedPost = 'creating'

/**
 * A wrapper class for {@link Post} that contains data relating to it being edited in the UI.
 */
class EditablePost {

    /**
     * The UUID of the post being edited.
     */
    id: string

    /**
     * The post being edited, with potentially new information than what is on the server.
     */
    post: PostCreate

    /**
     * The image of the post. This is undefined when not being edited.
     */
    image?: UploadedImage | undefined

    private _additionalAuthors: User[]

    set additionalAuthors(users: User[]) {
        this._additionalAuthors = users
        this.post.additionalAuthors = users.map(author => author.id)
    }

    get additionalAuthors() {
        return this._additionalAuthors
    }

    /**
     * The markdown editor object. If undefined, the post is not being edited.
     */
    editor: EasyMDE | undefined

    /**
     * Set before {@link editing} is actually true, this tells the post to begin the creation of the markdown editor.
     * Once the textarea is loaded in the editing display, the markdown editor is rendered and {@link editing} is true.
     */
    preEdit: boolean = false

    constructor(post: Post) {
        this.id = post.id
        // Because the system show has no authors, ALL authors are additional
        this._additionalAuthors = post.authors
        this.post = {
            title: post.title,
            content: post.content,
            posted: post.posted,
            image: post.image,
            additionalAuthors: post.authors.map(author => author.id)
        } as PostCreate;
    }
}

/**
 * A page to view all system posts and edit them.
 */
export const SystemPost = () => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * The previous/next links generated by the most recent listing request.
     */
    const [links, setLinks] = useState<PageLinks | undefined>()

    /**
     * The current page being shown, 1-indexed.
     */
    const [page, setPage] = useState<number>(1)

    /**
     * The posts being displayed.
     */
    const [posts, setPosts] = useState<EditablePost[]>([])

    /**
     * A temporary object that holds the post currently being created (and edited), made by {@link createNewPost}. If
     * cancelled, this is set back to {@code undefined}.
     */
    const [creatingPost, setCreatingPost] = useState<EditablePost | undefined>()

    /**
     * The UUID of the original setting of {@link setFeaturedPost}, to compare with after a save. Once a post is saved
     * as the new featured post, this will be set as its ID.
     */
    const [originalFeaturedPost, setOriginalFeaturedPost] = useState<string | undefined>()

    /**
     * The UUID of the featured post, which may or may not be visible in the current page. If the value is
     * {@link CreatingFeaturedPost}, then when the post being created is saved, its UUID will be set as featured.
     */
    const [featuredPost, setFeaturedPost] = useState<string | CreatingFeaturedPost | undefined>()

    /**
     * Additional options to pass to {@link Croppie} when uploading the post image.
     */
    const croppieOptions: CroppieOptions = {
        boundary: {
            width: 300 - 32, // 16px horizontal padding
            height: 300
        },
        enableResize: true,
    }

    /**
     * Initially gets a list of posts from {@link INITIAL_URL}.
     */
    useEffect(() => {
        refreshPosts()
        fetchFeaturedPost()
    }, [])

    /**
     * Fetches the featured post and sets it via {@link setFeaturedPost} and {@link setOriginalFeaturedPost}
     */
    const fetchFeaturedPost = (): void => {
        requestHandler.request('/api/show/post/featured')
            .then(async res => {
                let json = await res.json()
                let post = json as DetailedPost
                setFeaturedPost(post.id)
                setOriginalFeaturedPost(post.id)
            })
    }

    /**
     * Refreshes the current {@link posts} state with a copied array of {@link posts}, effectively causing the page to
     * re-render itself.
     */
    const refreshState = () => setPosts(old => [...old])

    /**
     * Refresh the {@link posts} using the paginated URL given.
     *
     * @param url The request URL, by default {@link INITIAL_URL}
     */
    const refreshPosts = (url: string = INITIAL_URL): void => {
        requestHandler.request(url)
            .then(async res => {
                let json = await res.json()
                setPosts((json.items as Post[]).map(post => new EditablePost(post)))
                setLinks(json._links as PageLinks)
            })
    }

    /**
     * If a {@link PageLinks.next} is defined for {@link links}, the {@link posts} list is refreshed with the next URL.
     */
    const nextPage = (): void => {
        if (links?.next != undefined) {
            refreshPosts(links?.next)
            setPage(old => old + 1)
        }
    }

    /**
     * If a {@link PageLinks.previous} is defined for {@link links}, the {@link posts} list is refreshed with the
     * previous URL.
     */
    const previousPage = (): void => {
        if (links?.previous != undefined) {
            refreshPosts(links?.previous)
            setPage(old => old - 1)
        }
    }

    /**
     * Sets {@link Post#preEdit} to true, so the editing view can start to be rendered.
     * @param e The mouse event to prevent default
     * @param post The post being edited
     */
    const clickEdit = (post: EditablePost, e: React.MouseEvent): void => {
        e.preventDefault();
        post.preEdit = true
        refreshState()
    }

    /**
     * Invoked when the textarea is rendered. This creates the markdown editor and sets the {@link Post} as editing.
     * @param post The {@link Post} being edited
     * @param textArea The textarea the editor uses
     */
    const beginEditing = (post: EditablePost, textArea: HTMLTextAreaElement | null) => {
        if (textArea != null && post.editor == undefined) {
            // @ts-ignore
            post.editor = new EasyMDE({
                element: textArea,
                initialValue: post.post.content,
                spellChecker: false,
                autosave: {
                    uniqueId: post.id,
                    enabled: true
                }
            })

            refreshState()
        }
    }

    /**
     * Stops any editing and reverts the view back to normal viewing mode.
     *
     * @param editablePost The post being switched back
     */
    const stopEditing = (editablePost: EditablePost): void => {
        editablePost.editor?.toTextArea()
        editablePost.editor = undefined
        editablePost.preEdit = false
        refreshState()
    }

    /**
     * Creates a post to be edited. The post is not saved in the backend yet.
     */
    const createNewPost = (): void => {
        setCreatingPost(new EditablePost({
            id: '',
            title: '',
            content: '',
            posted: Date.now(),
            image: '',
            authors: []
        } as Post))
    }

    /**
     * Deletes a post both by the API and locally.
     * 
     * @param editablePost The post to delete
     */
    const deletePost = (editablePost: EditablePost): void => {
        requestHandler.request(`/api/show/system/post/${editablePost.id}`, {method: 'DELETE'})
            .then(res => {
                if (res.status == 200) {
                    refreshPosts()
                    
                    toastHandler.addToast(new ToastInfo('Deleted post', `The post '${editablePost.post.title}' has been deleted.`))
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while deleting post. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }
            })
    }

    /**
     * Uploads the post image to the backend, and sets the {@link EditablePost#image} to the uploaded image.
     *
     * @param postId       The post ID to upload the image for
     * @param editablePost The {@link EditablePost} object
     */
    const uploadImage = (postId: string, editablePost: EditablePost): Promise<void> => {
        if (editablePost.image == undefined) {
            return Promise.resolve()
        }

        return ImageService.uploadPostImage(requestHandler, 'system', postId, editablePost.image.blob, url => editablePost.post.image = url)
    }

    /**
     * If a new {@link featuredPost} has been set, update the backend and reset {@link setOriginalFeaturedPost}.
     * 
     * @param postId The ID of the post being saved that triggered this action, if it was creating a post
     */
    const saveFeaturedPost = (postId?: string): Promise<void> => {
        let featuredCreating = featuredPost == 'creating'
        // If the featured post is the one pending creation but this was invoked without the creating ID, return || ...
        if ((featuredCreating && postId == undefined) || featuredPost == originalFeaturedPost) {
            return Promise.resolve()
        }

        let featuredId = featuredCreating ? postId : featuredPost
        console.log(`featured ID: "${featuredId}" and ${postId} or ${featuredPost}`);
        return requestHandler.request('/api/show/post/featured', {
            method: 'POST', body: JSON.stringify({
                id: featuredId
            })
        }).then(() => {
            setOriginalFeaturedPost(featuredPost)
        })
    }

    /**
     * Saves the post being edited, and on success, invoking {@link stopEditing} on it.
     *
     * @param editablePost The post being saved
     */
    const savePost = (editablePost: EditablePost): Promise<void> => {
        let post = editablePost.post
        post.content = editablePost.editor?.value() ?? ''
        return requestHandler.request(`/api/show/system/post/${editablePost.id}`, {
            method: 'POST',
            body: JSON.stringify(post)
        })
            .then(async res => {
                if (res.status == 200) {
                    await uploadImage(editablePost.id, editablePost)

                    toastHandler.addToast(new ToastInfo('Updated post', `The post '${post.title}' has been saved.`))
                    stopEditing(editablePost)
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while updating post. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }
            }).then(() => saveFeaturedPost())
    }

    /**
     * Submits to the API the created post, started by {@link createNewPost}.
     */
    const createPost = (): Promise<void> => {
        if (creatingPost == undefined) {
            return Promise.resolve()
        }

        let post = creatingPost.post
        post.content = creatingPost.editor?.value() ?? ''
        return requestHandler.request(`/api/show/system/post`, {method: 'POST', body: JSON.stringify(post)})
            .then(async res => {
                if (res.status == 200) {
                    let json = await res.json()
                    let createdPost = json as Post

                    await uploadImage(createdPost.id, creatingPost)

                    await saveFeaturedPost(createdPost.id)
                    fetchFeaturedPost()
                    toastHandler.addToast(new ToastInfo('Created post', `The post '${post.title}' has been created.`))

                    stopEditing(creatingPost)
                    setCreatingPost(undefined)
                    refreshPosts()
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while creating post. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }
            })
    }

    /**
     * Sets the post's {@link Post#posted} to the current time, refreshing the state.
     *
     * @param post The post to update
     */
    const setToCurrentTime = (post: PostCreate): void => {
        post.posted = new Date().getTime()
        refreshState()
    }

    /**
     * Updates the post's title and refreshes the state.
     *
     * @param post  The post who's title to update
     * @param title The new title
     */
    const updateTitle = (post: PostCreate, title: string) => {
        post.title = title
        refreshState()
    }

    /**
     * Updates the post's authors and refreshes the state.
     *
     * @param editablePost  The post wrapper to update
     * @param authors The new authors of the post
     */
    const updateAuthors = (editablePost: EditablePost, authors: User[]): void => {
        editablePost.additionalAuthors = authors
        refreshState()
    }

    /**
     * Checks if the given post is featured. If {@link creatingPost} is {@code true} (it defaults to {@code false}) and
     * {@link featuredPost} is {@link CreatingFeaturedPost}, this will return {@link true}. Otherwise, this is just an
     * ID comparison.
     *
     * @param post         The post to check if it is featured
     * @param creatingPost If the post is currently being created
     */
    const isPostFeatured = (post: EditablePost, creatingPost?: boolean): boolean => {
        let normalFeatured = post.id == featuredPost;
        let createdFeatured = (creatingPost ?? false) && featuredPost == 'creating'
        return normalFeatured || createdFeatured
    }

    /**
     * Displays an element of the filled-in featured star if the provided post is featured, otherwise returning an
     * empty Fragment. If {@link creatingPost} is {@code true} (it defaults to false) and {@link featuredPost} is
     * {@link CreatingFeaturedPost}, the star will be returned.
     * 
     * @param post         The post to check if it is featured
     * @param creatingPost If the post is currently being created
     */
    const displayFeaturedStar = (post: EditablePost, creatingPost?: boolean): JSX.Element => {
        return <>{isPostFeatured(post, creatingPost) &&
            <span className="star text-warning material-symbols-outlined inline-icon me-2" title="Featured post">star</span>}</>
    }

    /**
     * Renders the post in display mode, i.e. not being edited.
     *
     * @param editablePost The post to display
     */
    const renderDisplay = (editablePost: EditablePost) => (
        <div key={editablePost.id} className="post card mb-3 w-100">
            <span className="edit text-muted position-absolute mt-2 me-3" onClick={(e) => clickEdit(editablePost, e)}>Edit</span>
            <div className="card-body">
                <h5 className="card-title">{displayFeaturedStar(editablePost)}{editablePost.post.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{editablePost.additionalAuthors.length != 0 ? combineNames(editablePost.additionalAuthors) : '-'} &bull; {moment(editablePost.post.posted).format('LLL')}</h6>
                {editablePost.post.image != '' && <div className="post-image-display mb-3 mt-3">
                    <img src={editablePost.post.image}/>
                </div>}
                <p className="card-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(editablePost.post.content))}}/>
            </div>
        </div>
    )

    /**
     * Renders the {@link creatingPost} in a {@link renderEdit} component.
     */
    const renderCreate = (): JSX.Element => {
        return renderEdit(creatingPost!, true, 'Create', () => createPost(), () => setCreatingPost(undefined))
    }

    /**
     * Renders a post in edit mode, where you can change and save the post contents.
     *
     * @param editablePost   The post to edit
     * @param creatingPost   If the post is being created. This will set {@link featuredPost} as
     *                       {@link CreatingFeaturedPost} upon setting as featured
     * @param saveText       The text shown as confirmation to save changes
     * @param saveCallback   The callback invoked when the save button is pressed
     * @param cancelCallback The callback invoked when editing is cancelled
     */
    const renderEdit = (editablePost: EditablePost, creatingPost: boolean, saveText: string, saveCallback: (editablePost: EditablePost) => void, cancelCallback: (editablePost: EditablePost) => void): JSX.Element => {
        const makeEditingFeatured = (): void => {
            if (creatingPost) {
                setFeaturedPost('creating')
            } else {
                setFeaturedPost(editablePost.id)
            }
        }
        
        let post = editablePost.post
        return (
            <div key={`${editablePost.id}-editing`} className="post card mb-3 w-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="d-flex">
                                <div className="flex-shrink-1 d-flex align-items-center">
                                    {displayFeaturedStar(editablePost, creatingPost)}
                                </div>
                                <input type="text" name="name" placeholder="Post title" className="form-control underline-input" value={editablePost.post.title} onChange={e => updateTitle(post, e.target.value)}/>
                            </div>

                            <div className="d-flex flex-row mt-3">
                                <span className="posted-label me-2">Posted:</span>
                                <SearchDateContext.Provider value={{
                                    date: new Date(post.posted),
                                    setDate: date => post.posted = (date?.getTime() ?? 0)
                                }}>
                                    <DropdownDate className="mx-0"/>
                                </SearchDateContext.Provider>
                                <button className="btn btn-link" onClick={e => setToCurrentTime(post)}>Update to now
                                </button>
                            </div>

                            <div className="d-flex flex-row mt-3">
                                <UserSelector selectedUsers={editablePost.additionalAuthors} setSelectedUsers={setFunction => updateAuthors(editablePost, setFunction(editablePost.additionalAuthors))} maxSelected={3} minimumRole={Role.staff}/>
                            </div>

                            {!isPostFeatured(editablePost, creatingPost) &&
                                <Button className="mt-3" onClick={() => makeEditingFeatured()} inactive>Set Featured</Button>}
                        </div>
                        <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                            <UploadImage className="upload-image" title="Set post image" imagePreview={editablePost.image?.preview ?? post.image} croppieOptions={croppieOptions} imageUploaded={(imageBlob, base64Preview) => {
                                editablePost.image = {blob: imageBlob, preview: base64Preview}
                                refreshState()
                            }}/>
                        </div>
                    </div>

                    <textarea ref={ref => beginEditing(editablePost, ref)} className="editor-textarea"/>
                    <div>
                        <button className="btn btn-primary me-2" onClick={() => saveCallback(editablePost)}>{saveText}</button>
                        <button className="btn btn-secondary" onClick={() => cancelCallback(editablePost)}>Cancel</button>
                        {!creatingPost &&
                            <button className="btn btn-danger float-end" onClick={() => deletePost(editablePost)} disabled={isPostFeatured(editablePost)}>Delete</button>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="SystemPost">
            <h3 className="text-orange mb-3">Edit System Posts</h3>
            <Button onClick={() => createNewPost()}>Create Post</Button>

            <div className="mt-3">
                <div className="d-flex flex-column">
                    {creatingPost != undefined && renderCreate()}
                    {posts.map(post => post.preEdit ? renderEdit(post, false, 'Save', savePost, stopEditing) : renderDisplay(post))}
                </div>
            </div>

            <div className="mt-3">
                <Button onClick={() => previousPage()} disabled={links?.previous == undefined}>Previous</Button>
                <span className="mx-3">Page {page}</span>
                <Button onClick={() => nextPage()} disabled={links?.next == undefined}>Next</Button>
            </div>
            <link rel="stylesheet" href="/static/css/easymde.min.css"/>
        </div>
    )
}
