import {Dispatch, SetStateAction} from "react";

/**
 * The type returned by {@link React.useState} hook.
 */
export type State<S> = [S, Dispatch<SetStateAction<S>>]

/**
 * A CSS class name that can be toggled.
 */
type ConditionalClass = [string, boolean]

/**
 * Removes all undefined elements of the given classes array, and joins them with spaces to be used as an HTML
 * element's className property.
 *
 * @param classes The classes to insert (if not undefined)
 */
export function classes(...classes: (string | ConditionalClass | undefined)[]): string {
    return classes.map((e) => {
        if (e == undefined) {
            return undefined
        }
        
        if (typeof e != 'string') {
            return e[1] ? e[0] : undefined
        }

        return e
    }).filter(e => e != undefined).join(" ")
}
