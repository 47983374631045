import { useRef, useEffect } from 'react';

/**
 * A hook to detect if the component is currently in its first render.
 */
export const useIsFirstRender = () => {
    const isFirstRender = useRef(true)
    
    useEffect(() => {
        isFirstRender.current = false
    }, []);
    
    return isFirstRender.current;
};